<template>
    <section>
        <div class="d-flex items-center justify-content-end arabicDirInit">
            <CustomerDropdown @filterCustomerId="filterActivity" class="mx-2" />
            <date-picker
                v-model="start_date"
                @change="getRevisions"
                format="YYYY-MM-DD"
                type="date"
                :placeholder="$t('Date Start')"
                style="width: 150px"
                class="bg-white ml-2"
            ></date-picker>
            <date-picker
                style="width: 150px"
                class="bg-white ml-2"
                v-model="end_date"
                @change="getRevisions"
                format="YYYY-MM-DD"
                type="date"
                :placeholder="$t('Date End')"
            ></date-picker>
        </div>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table
                head-variant="dark"
                class="text-center"
                small
                :items="revisions.data"
                show-empty
                bordered
                :fields="fields"
                :busy="loading"
                fixed
                responsive
            >
            <template #cell(name)="data">
                    <span>{{data.item.first_name+ " "+ data.item.last_name}}</span>
                </template>
                <template #cell(revisionable_type)="data">
                    <span v-if="data.item.revisionable_type">
                        {{data.item.revisionable_type.replace("App\\Models\\"," ")}}</span>
                </template>

                <template #cell(created_at)="data">
                    <span v-if="data.item.created_at">{{
                        data.item.created_at | moment
                    }}</span>
                </template>

                <template #table-busy>
                    <table-loader />
                </template>
            </b-table>
            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="revisions.total"
                @input="getRevisions"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
        </b-card>
    </section>
</template>

<script>
import moment from "moment";
import CustomerDropdown from "@/components/User/CustomerDropdown";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import TableLoader from "@/components/tableLoader";
export default {
    name: "Revisions",
    components: {
        CustomerDropdown,
        DatePicker,
        TableLoader,
    },
    computed: {
        fields() {
            // return [
            //     {
            //         label: this.$t('#'),
            //         key: 'id'
            //     },
            //     {
            //         label: this.$t('User'),
            //         key: 'user.name'
            //     },
            //     {
            //         label: this.$t('Action'),
            //         key: 'log_name'
            //     },
            //     {
            //         label: this.$t('Description'),
            //         key: 'description'
            //     },
            //     {
            //         label: this.$t('Date'),
            //         key: 'created_at',
            //     },
            // ]
            return [
                {
                    label: this.$t("User"),
                    key: "name",
                },
                {
                    label: this.$t("Module Type"),
                    key: "revisionable_type",
                },
                {
                    label: this.$t("Module ID"),
                    key: "revisionable_id",
                },
                {
                    label: this.$t("Field"),
                    key: "key",
                },
                {
                    label: this.$t("Previous"),
                    key: "old_value",
                },
                {
                    label: this.$t("New"),
                    key: "new_value",
                },
                {
                    label: this.$t("Date"),
                    key: "created_at",
                },
            ];
        },
    },
    data() {
        return {
            currentPage: 1,

            revisions: [], // table rows
            filterTag: "",
            customer_id: "",
            loading: false,
            start_date: null,
            end_date: null,
            customers: [],
        };
    },
    created() {
        this.getRevisions();
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY h:mma");
        },
    },
    methods: {
        async getRevisions() {
            try {
                this.loading = true;
                const start_date =
                    this.start_date !== null
                        ? moment(this.start_date).format("YYYY-MM-DD")
                        : null;
                const end_date =
                    this.end_date !== null
                        ? moment(this.end_date).format("YYYY-MM-DD")
                        : null;

                const { data } = await this.$axios.get(
                    `revisions_log?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&page=${this.currentPage}`
                );

                // const {data} = await this.$axios.get(
                //     `revisions_log?page=${this.currentPage}`
                // );
                this.revisions = data;
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },

        filterActivity(value) {
            this.customer_id = value;
            this.getRevisions();
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

.contact-input {
    border-radius: 9999px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

.contact-textarea {
    border-radius: 20px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

#contactMail {
    @include sm {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>

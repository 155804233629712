<template>
    <section>
        <b-card class="shadow-sm">
            <div class="d-flex justify-content-end align-items-center mb-4">
                <b-button v-b-modal.notificationSettings class="primary-btn ml-3" variant="info" size="sm">
                    {{$t('Alert settings')}}
                </b-button>
            </div>
            <b-table head-variant="dark" :items="items" :fields="fields"  class="text-center" small bordered>

            </b-table>
        </b-card>
        <b-modal centered hide-header hide-footer id="notificationSettings">
            <NotificationSettings></NotificationSettings>
        </b-modal>
    </section>
</template>
<script>
import NotificationSettings from "@/components/Notification/NotificationSettings";

export default {
    name: "Notifications",
    components: {NotificationSettings},
    computed:{
        fields() {
            return [
                {
                    label: this.$t('Description'),

                    key: 'description'
                }
            ]
        }
    },
    data() {
        return {
            items: [],

        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="mt-4">
            <div class="d-flex justify-content-between">
                <h3 class="text-capitalize">#Sadad Pending Invoices</h3>
                <div class="d-flex items-center justify-content-end">
                    <b-button
                        class="btn px-3 ml-2 pr-2 btn-info"
                        @click="sendSmsToSelected"
                        >SMS</b-button
                    >
                    <CustomerDropdown
                        @filterCustomerId="filterSadad"
                        class="mx-2"
                    />
                    <date-picker
                        v-model="start_date"
                        @change="getSadadTransactions"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Issue Date Start')"
                        style="width: 150px"
                        class="bg-white ml-2"
                    ></date-picker>
                    <date-picker
                        style="width: 150px"
                        class="bg-white ml-2"
                        v-model="end_date"
                        @change="getSadadTransactions"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Issue Date End')"
                    ></date-picker>
                    <b-input
                        style="width: 200px"
                        class="bg-white ml-2"
                        v-model="search"
                        @keyup="getSadadTransactionsBySearch"
                        @blur="getSadadTransactions"
                        :placeholder="$t('Search by SADAD')"
                    ></b-input>
                </div>
            </div>
            <b-card class="shadow-sm mt-2" body-class="p-0">
                <b-table
                    :busy="loading"
                    head-variant="dark"
                    small
                    :items="items.data"
                    :tbody-tr-class="rowClass"
                    show-empty
                    bordered
                    :fields="fields"
                    responsive
                    class="text-center"
                >
                    <template #cell(sms)="data">
                        <div
                            v-if="data.item.status === 'pending'"
                            class="text-center"
                        >
                            <b-form-checkbox
                                class="payCheckbox"
                                @change="
                                    (checked) =>
                                        checkoutHandler(
                                            checked,
                                            data.item,
                                            'sadad'
                                        )
                                "
                            >
                            </b-form-checkbox>
                        </div>
                    </template>
                    <template #cell(sadad_number)="data">
                        <b-link
                            v-if="data.item.sadad_number"
                            class="text-primary"
                            :to="`/sadad-transactions-invoices/${data.item.sadad_number}`"
                        >
                            {{ data.item.sadad_number }}
                        </b-link>
                    </template>
                    <template #cell(issue_date)="data">
                        {{ data.item.issue_date | moment }}
                    </template>
                    <template #cell(amount)="data">
                        {{ data.item.amount | comma_separated }}
                    </template>

                    <template #cell(expire_date)="data">
                        {{ data.item.expire_date | moment }}
                    </template>
                    <template #cell(last_notified)="data">
                        {{ data.item.last_notified | moment }}
                    </template>
                    <template #cell(actions)="data">
                        <div class="d-flex justify-content-center">
                            <b-link
                                v-if="data.item.sadad_number"
                                class="text-primary"
                                :to="`/sadad-transactions-invoices/${data.item.sadad_number}`"
                            >
                                <b-icon
                                    variant="info"
                                    font-scale="1.5"
                                    icon="eye-fill"
                                ></b-icon>
                            </b-link>
                            <span
                                v-if="data.item.status == 'pending'"
                                @click="
                                    cancelSdadaInvoices(
                                        data.item.bill_number,
                                        data.item.sadad_number
                                    )
                                "
                                class="ml-2"
                                variant="danger"
                            >
                                <b-icon
                                    variant="danger"
                                    font-scale="1.5"
                                    icon="file-excel-fill"
                                ></b-icon
                            ></span>
                        </div>
                    </template>
                </b-table>

                <b-pagination
                    aria-controls="my-table"
                    size="sm"
                    per-page="15"
                    v-model="currentPage"
                    :total-rows="items.total"
                    @input="getSadadTransactions"
                    prev-text="Prev"
                    next-text="Next"
                    class="justify-content-center"
                ></b-pagination>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    confirmCancelAlert,
    successAlert,
    errorAlert,
} from "@/components/Theme/Alerts";
import CustomerDropdown from "@/components/User/CustomerDropdown";

import moment from "moment";
import isEmpty from "lodash.isempty";
import DatePicker from "vue2-datepicker";
import { cloneDeep } from "lodash";
import Swal from "sweetalert2";

export default {
    name: "SadadPendingInvoices",
    components: {
        CustomerDropdown,
        DatePicker,
    },

    data() {
        return {
            customer_id: "",
            loading: false,
            checkedSadad: {},
            items: [],
            currentPage: 1,
            statusColor: {
                paid: "table-success",
                pending: "table-warning",
                canceled: "table-danger",
                expired: "table-secondary",
            },
            start_date: null,
            end_date: null,
            search: null,
            sadadToTextIds: [],
        };
    },
    created() {
        this.getSadadTransactions();
    },
    methods: {
        async sendSmsToSelected() {
            if (!isEmpty(this.checkedSadad["sadad"])) {
                const { value: text } = await Swal.fire({
                    input: "textarea",
                    inputLabel:
                        "{Customer Name} SADAD No {sadad_no} Amount: SR.{amount}.  Expires on {d-m-Y} ______ Saudi Diesel Equipment Company. \n\r * Your message will be replaced inside the dashes",
                    inputPlaceholder: "Type your message here...",
                    inputAttributes: {
                        "aria-label": "Type your message here",
                    },
                    showCancelButton: true,
                });

                if (text) {
                    const sadadToSms = [];
                    for (const invoiceArr in this.checkedSadad) {
                        this.checkedSadad[invoiceArr].forEach((item) => {
                            sadadToSms.push(item.sadad_number);
                        });
                    }
                    const data = {
                        message: text,
                        sadad: sadadToSms,
                    };
                    try {
                        this.loading = true;
                        await this.$axios.post("sadad_notify_selected", data);

                        await successAlert("Notification Sent", "successful");

                        await this.getSadadTransactions();
                        this.checkedSadad = {};
                        this.loading = false;
                    } catch (e) {
                        this.loading = false;
                        console.log("error ");
                    }
                }
            } else {
                errorAlert(
                    "No Records Selected",
                    "Please Select Sadad invoices to send sms"
                );
            }
        },
        getSadadTransactionsBySearch() {
            if (this.search.length >= 3) {
                this.getSadadTransactions();
            }
        },
        filterSadad(value) {
            this.customer_id = value;
            this.getSadadTransactions();
        },
        checkoutHandler(checked, item, categoryName) {
            const checkedSadad = cloneDeep(this.checkedSadad);
            if (checked) {
                if (categoryName in checkedSadad) {
                    //already invoice added in the category
                    checkedSadad[categoryName].push(item);
                } else {
                    checkedSadad[categoryName] = []; //create new  and push it
                    checkedSadad[categoryName].push(item);
                }
            } else {
                checkedSadad[categoryName] = checkedSadad[categoryName].filter(
                    (invoice) => invoice.id !== item.id
                );
                if (checkedSadad[categoryName].length === 0) {
                    delete checkedSadad[categoryName];
                }
            }
            this.checkedSadad = checkedSadad;
        },

        async getSadadTransactions() {
            try {
                this.loading = true;
                const start_date =
                    this.start_date !== null
                        ? moment(this.start_date).format("YYYY-MM-DD")
                        : null;
                const end_date =
                    this.end_date !== null
                        ? moment(this.end_date).format("YYYY-MM-DD")
                        : null;
                const search =
                    this.search !== null
                        ? encodeURIComponent(this.search.trim())
                        : null;
                const { data } = await this.$axios.get(
                    `sadadPendingInvoices?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${this.currentPage}`
                );
                this.items = data;
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        /* async getSadadTransactions() {
            const {data} = await this.$axios.get(
                `sadadTransactions?currentPage=${this.currentPage}`
            );
            this.items = data;

        },*/
        rowClass(item, type) {
            if (!item || type !== "row") return;
            return this.statusColor[item.status];
        },
        async cancelSdadaInvoices(bill_number, sadad_number) {
            const { isConfirmed } = await confirmCancelAlert("Sadad Invoices");
            if (isConfirmed) {
                let data = {
                    bill_number: bill_number,
                    sadad_number: sadad_number,
                };
                await this.$axios.post("cancelSadadInvoicesById", data);

                await successAlert("Success!", "successful");
                await this.getSadadTransactions();
            }
        },
    },
    computed: {
        fields() {
            return [
                {
                    label: "#",
                    key: "sms",
                    visible: true,
                    thStyle: {
                        width: "30px",
                    },
                },
                {
                    label: this.$t("Bill#"),
                    key: "bill_number",
                },
                {
                    label: this.$t("SADAD#"),
                    key: "sadad_number",
                },
                {
                    label: this.$t("Customer"),
                    key: "user.name",
                    thStyle: {
                        width: "90px",
                    },
                },
                {
                    label: this.$t("Payment Method"),
                    key: "payment_method.name",
                },
                {
                    label: this.$t("Amount"),
                    key: "amount",
                },
                {
                    label: this.$t("Issue Date"),
                    key: "issue_date",
                },
                {
                    label: this.$t("Expiry Date"),
                    key: "expire_date",
                },
                {
                    label: this.$t("Last Notified"),
                    key: "last_notified",
                },
                {
                    label: this.$t("Status"),
                    key: "status",
                },
                {
                    label: this.$t("Actions"),
                    key: "actions",
                },
            ];
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == "all") {
                this.filterTag = "";
                return;
            } else {
                this.filterTag = to.params.type;
            }
        },
    },
};
</script>

<style scoped></style>

<template>
    <div>

        <form @submit.prevent="save">
            <validation-observer ref="userForm">
                <b-form-row>
                    <b-col sm="6">
                        <b-form-group label="First Name *">
                            <template #label>{{ $t(('First Name')) }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.first_name" rules="required"
                                            name="first name"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Last Name">
                            <template #label>{{ $t(('Last Name')) }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.last_name" rules="required" name="last name"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Email *">
                            <template #label>{{ $t(('Email')) }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.email" rules="required" name="email"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group :label=" $t(('Phone *'))">
                            <template #label>{{ $t(('Phone')) }} <span class="text-danger">*</span></template>
                            <text-validator  rules="required" v-model="user.phone" name="phone"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" v-if="type =='edit'">
                        <b-form-group label="Password">
                            <template #label>{{ $t(('Password')) }}  </template>
                            <text-validator v-model="user.password" type="password"
                                            name="password"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" v-if="type !='edit'">
                        <b-form-group label="Password">
                            <template #label>{{ $t(('Password')) }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.password" rules="required" type="password"
                                            name="password"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6"  v-if="type =='edit'">
                        <b-form-group label="Confirm Password">
                            <template #label>{{ $t(('Confirm Password')) }}</template>
                            <text-validator v-model="user.password_confirmation" type="password"
                                            name="password confirmation"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6"  v-if="type !='edit'">
                        <b-form-group label="Confirm Password">
                            <template #label>{{ $t(('Confirm Password')) }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.password_confirmation" type="password" rules="required"
                                            name="password confirmation"></text-validator>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group :label=" $t(('Address'))" class="col-span-2">
                    <text-validator v-model="user.address" name="address"></text-validator>
                </b-form-group>
                <b-form-row>
                    <b-col sm="6">
                        <b-form-group label="Role">
                            <template #label>{{ $t(('Role')) }} <span class="text-danger">*</span></template>
                            <select-validator v-model="user.role" name="role" value-field="name" label="name"
                                              :options="roles"
                                              rules="required"></select-validator>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="user.role === constRoles.ACCOUNTANT" sm="6">
                        <b-form-group label="Role">
                            <template #label>{{ $t(('Departments')) }} </template>
                            <select-validator v-model="user.departments" value-field="id" multiple name="customers"
                                              label="name"
                                              :options="departments.data"
                                              rules="required"></select-validator>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="user.role === constRoles.CUSTOMER" sm="6">
                        <b-form-group label="Role">
                            <template #label>{{ $t(('Departments')) }}</template>
                            <select-validator v-model="user.department_id" value-field="id" name="department"
                                              label="name"
                                              :options="departments.data"
                                              rules="required"></select-validator>
                        </b-form-group>
                    </b-col>

                </b-form-row>
                <b-form-row>
                    <b-col sm="6">
                        <b-form-group label="CRR ID">
                            <template #label>{{ $t(('CRR ID')) }}</template>
                            <text-validator v-model="user.customer_id_number" name="customer_id_number"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" v-if="user.image">
                        <img class="userImage p-3" width="30%" :src="`${user.image.path}`" alt="">
                        <b-form-group label="Change Image " class="col-span-2">
                            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" v-else>
                        <b-form-group :label="$t(('Image'))" class="col-span-2">
                            <input type="file" id="file2" ref="file" v-on:change="handleFileUpload()"/>
                        </b-form-group>

                    </b-col>

                </b-form-row>
                <b-form-row>
                    <b-col sm="6">
                        <b-form-group label="Customer No">
                            <template #label>{{ $t(('Customer No')) }}</template>
                            <text-validator v-model="user.customer_number" name="customer_number"></text-validator>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Payer No">
                            <template #label>{{ $t(('Payer No')) }}</template>
                            <text-validator v-model="user.payer_number" name="payer_number"></text-validator>
                        </b-form-group>
                    </b-col>



                </b-form-row>
                <div class="text-right">
                    <button @click="save" class="primary-btn green">{{ $t(('Save')) }}</button>
                </div>
            </validation-observer>
        </form>
    </div>
</template>

<script>
import {errorAlert, successAlert, validationAlert} from "@/components/Theme/Alerts";
import {ValidationObserver} from "vee-validate";
import TextValidator from "@/components/Validators/TextValidator";
import {roles, user} from "@/constants";
import SelectValidator from "@/components/Validators/SelectValidator";

export default {
    name: "UserForm",
    components: {
        SelectValidator,
        TextValidator,
        ValidationObserver
    },
    data() {
        return {
            user: {
                ...user,
                password: '',
                password_confirmation: '',
            },
            roles: [],
            constRoles: roles,
            customers: [],
            departments: {},
            file: '',
        }
    },
    props: {
        type: {
            type: String,
            default: 'add'
        }
    },
    created() {
        this.getRoles()
        if (this.type === 'edit') {
            this.getUser(this.$route.params.id)
        }
        this.getDepartments()
    },
    methods: {
        async save(e) {
            e.preventDefault()
            let fd = new FormData();
            for (const property in this.user) {
                fd.append(`${property}`, `${this.user[property]}`);
                // console.log(`${property}`, `${this.user[property]}`);
            }
            fd.append('file', this.file);

            const valid = await this.$refs.userForm.validate();
            if (valid) {
                let url, method;
                method = 'post';
                if (this.type === 'add') {
                    url = '/user';

                    // fd.append('_method', 'post');
                } else {
                    url = `/user/${this.user.id}`
                    // method = 'put';
                    fd.append('_method', 'put');
                }
                try {
                    await this.$axios[method](url, fd);
                    await successAlert('User ', 'created')
                    this.$router.push('/users');
                } catch (e) {
                    if (e.response.status === 421) {
                        await errorAlert(e.response.data.message)
                    }else if (e.response.status === 422) {
                        await validationAlert(e.response.data.errors)
                    }else{
                        await errorAlert('Something went wrong', 'server error')
                    }
                }
            }
        },
        async getRoles() {
            const {data} = await this.$axios.get('role');
            this.roles = data.roles;
        },
        async getUser(id) {
            const {data} = await this.$axios.get(`user/${id}`)
            if (data.roles.length > 0) {
                this.user = {
                    ...data,
                    role: data.roles[0].name
                }
            } else {
                this.user = data
            }
            // console.log(this.user.image.path);
        },
        async getCustomers() {
            const {data} = await this.$axios.get(`user?role=${this.constRoles.CUSTOMER}`)
            this.customers = data
        },
        async getDepartments() {
            const {data} = await this.$axios.get('department');
            this.departments = data;
        },
        setAltAvatar() {

        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
    }
}
</script>

<style scoped>

</style>

<template>
    <Select @input="filterRecords" v-model="customer_id" style="min-width: 190px; max-width: 380px" class="bg-white dropdownFix"
            label="last_name" :placeholder="$t('Select Customer')" :reduce="customer => customer.id"
            :options="customers"></Select>
</template>

<script>
import {roles} from "@/constants";
import Select from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: "CustomerDropdown",
    props: [],
    components: {
        Select: Select
    },
    data() {
        return {
            customers: [],
            customer_id: '',
        }
    },
    methods: {
        async getCustomers() {
            const {data} = await this.$axios.get(`user?role=${roles.CUSTOMER}`)
            this.customers = data
        },
        filterRecords() {
            this.$emit('filterCustomerId', this.customer_id);
        },
    },
    created() {
        this.getCustomers();
    }

}
</script>

<style scoped>

</style>

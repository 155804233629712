<template>
    <div>
        <section>
            <h3 class="section-heading arabicDir">{{ $t('Financial Summary') }}</h3>
            <Stats :hidden="['total_amount']" :invoices-total="invoicesTotal"/>
        </section>

        <!--        //new code-->
        <section>
            <div class="grid grid-cols-2 gap-x-6">
                <div>
                    <h3 class="section-heading arabicDir">{{ $t('Invoice Chart') }}</h3>
                    <b-card body-class="p-0" class="shadow-sm border">
                        <pie :width="300" :height="300" ref="pie" :chart-data="chartData" :options="options"></pie>
                    </b-card>
                </div>
                <div class="">
                    <h3 class="section-heading arabicDir">{{ $t('Payment History') }}<span class="text-base text-capitalize">{{ $t('(Last 12 Months)') }}</span>
                    </h3>
                    <b-table responsive head-variant="dark" :items="items.data" tbody-tr-class="bg-white"
                             class="shadow-sm"
                             show-empty bordered
                             :fields="fields">
                        <template #cell(amount)="data">
                            {{data.item.amount | comma_separated}}
                        </template>
                        <template #cell(invoices)="data">
                             <div v-if="data.item.invoices[0]">
                                 <b-link
                                     v-if="data.item.invoices[0].installment"
                                     class="text-primary"
                                     :to="`/contract-details/${data.item.invoices[0].installment.contract.number}`"
                                 >
                                     {{ data.item.invoices[0].installment.contract.number}}
                                 </b-link>  <span class="ml-2"> -  {{ data.item.invoices[0].installment.ref_number}}</span>
                             </div>
                        </template>
                        <template #cell(created_at)="data">
                            {{ data.item.created_at | moment }}
                        </template>
                    </b-table>
<!--                    <div class="text-right">
                        <button class="primary-btn"><img src="./../assets/images/icons/statement.png" alt="">Statement
                        </button>
                        <button class="primary-btn ml-2">
                            <b-icon icon="printer"></b-icon>
                            Print
                        </button>
                    </div>-->
                </div>
            </div>
        </section>


        <!--        <section>-->
        <!--            <h3 class="section-heading">Invoice Chart</h3>-->
        <!--            <b-card class="shadow-sm border">-->
        <!--                <pie :chart-data="chartData" :options="options"></pie>-->
        <!--            </b-card>-->

        <!--        </section>-->
        <!--        <section>-->
        <!--            <h3 class="section-heading">Payment History <span class="text-base text-capitalize">(Last 12 Months)</span>-->
        <!--            </h3>-->
        <!--            <b-table responsive head-variant="dark" :items="items.data" tbody-tr-class="bg-white" class="shadow-sm"-->
        <!--                     show-empty bordered-->
        <!--                     :fields="fields">-->
        <!--                <template #cell(created_at)="data">-->
        <!--                    {{ data.item.created_at | moment }}-->
        <!--                </template>-->
        <!--            </b-table>-->
        <!--            <div class="text-right">-->
        <!--                <button class="primary-btn"><img src="./../assets/images/icons/statement.png" alt="">Statement</button>-->
        <!--                <button class="primary-btn ml-2">-->
        <!--                    <b-icon icon="printer"></b-icon>-->
        <!--                    Print-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </section>-->
    </div>
</template>

<script>

import Pie from "@/components/Theme/Charts/Pie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
import Stats from "@/components/Stats";

export default {
    name: "Home",
    components: {Stats, Pie},
    computed:{
        fields() { return [
            {
                label: this.$t('Paid Date'),
                key: 'paid_date'
            },
            {
                label: this.$t('Contract - Installment Reference'),
                key: 'invoices'
            },

            {
                label: this.$t('Payment Method'),
                key: 'payment_method.name'
            },
            {
                label: this.$t('SADAD#'),
                key: 'sadad_number'
            },
            {
                label: this.$t('Amount'),
                key: 'amount'
            }
        ]
        }
    },
    data() {
        return {

            items: {},
            chartData: {
                labels: [],
                datasets: []
            },
            invoicesTotal: {},
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position:'right'
                },
                onClick: this.chartClickHandler,
                layout: {
                    padding: {
                        top: 50,
                        bottom: 50
                    }
                },
                plugins: {
                    datalabels: {
                        color: 'white',
                        // font: {
                        //     weight: 'bold'
                        // },
                        anchor: 'end',
                        align: 'end',
                        formatter: function (value, context) {
                            return `${context.chart.data.labels[context.dataIndex]}\n${(context.dataset.data[context.dataIndex].toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} SAR`;
                        }
                    }
                },
            }
        }
    },
    methods: {
        chartClickHandler(e) {
            const chart = this.$refs.pie.$data._chart;
            const activePoints = chart.getElementsAtEvent(e);

            if (activePoints.length > 0) {
                //get the internal index of slice in pie chart
                const clickedElementIndex = activePoints[0]["_index"];

                //get specific label by index
                const label = chart.data.labels[clickedElementIndex];
                const invoiceType = label.substring(0, label.indexOf(' '));
                //Redirect to specific invoice
                this.$router.push({name: 'Invoices', params: {type: invoiceType}})
            }
        },
        async getValues() {
            const {data} = await this.$axios.get('invoicesTotal');

            let tempData = {...data};
            tempData.upcoming_balance = tempData.undue_balance;
            delete tempData.total_amount;
            delete tempData.undue_balance;
            this.makeChartData(tempData);
            this.invoicesTotal = data
        },

        async getPayments() {
            const {data} = await this.$axios.get('payment');
            this.items = data;
        },
        makeChartData(invoiceData) {
            const labels = []
            const data = [];
            Object.keys(invoiceData).forEach(item => {
                labels.push(this.$t(item.replace('_', ' ')));
                data.push(invoiceData[item])
            })
            this.chartData = {
                labels: labels,
                plugins: [ChartDataLabels],
                datasets: [
                    {
                        label: 'Data One',
                        backgroundColor: ['#7a62ba', '#4098d7', '#d8b655', '#00911a'],
                        data: data
                    },
                ]
            }
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    created() {
        this.getValues();
        this.getPayments();
    }

}
</script>

<style lang="scss">


</style>

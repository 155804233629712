import Axios from 'axios';
import {router} from "@/routes";
// import {router} from "@/routes";
// process.env.VUE_APP_AXIOS_URL
const user = JSON.parse(localStorage.getItem('user'));
const axiosInstance = Axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api' : 'https://customer-portal-api.saudidiesel.com.sa/api',
    headers: {
        Authorization: `Bearer ${user ? user.token : null}`
    }
});
axiosInstance.interceptors.response.use(res => {
        return res
    },
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                if (router.currentRoute.name !== 'login') {
                    router.push('/login').then(() => {
                        axiosInstance.post('logout');
                        localStorage.removeItem('user')
                    })
                }
            }
        }
        return Promise.reject(error)
    })
export default axiosInstance

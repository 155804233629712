<template>
    <section>
        <b-card class="py-5">
            <div id="contactMail" class="grid grid-cols-1 items-center">
                <div class="text-center">
                    <b-icon
                        icon="envelope"
                        style="color: #1c7430"
                        font-scale="12"
                    ></b-icon>
                </div>
                <div>
                    <h2 class="font-weight-bold text-2xl arabicDir">
                        {{ $t("Get in touch") }}
                    </h2>
                    <form @submit="send" action="">
                        <validation-observer ref="supportForm">
                            <b-form-group>
                                <b-input
                                    class="contact-input"
                                    name="name"
                                    v-model="contact.name"
                                    required
                                    :placeholder="$t('Name')"
                                ></b-input>
                            </b-form-group>
                            <b-form-group>
                                <b-input
                                    class="contact-input"
                                    name="email"
                                    v-model="contact.email"
                                    required
                                    :placeholder="$t('Email')"
                                ></b-input>
                            </b-form-group>
                            <b-form-group>
                                <b-textarea
                                    class="contact-textarea"
                                    name="message"
                                    v-model="contact.message"
                                    required
                                    :placeholder="$t('Message')"
                                ></b-textarea>
                            </b-form-group>
                            <button
                                class="primary-btn w-100 green rounded-full"
                            >
                                {{ $t("Send") }}
                            </button>
                        </validation-observer>
                    </form>
                </div>
            </div>
        </b-card>
    </section>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";

export default {
    name: "support",
    components: { ValidationObserver },
    data() {
        return {
            contact: {
                name: "",
                email: "",
                message: "",
            },
        };
    },
    methods: {
        async send(e) {
            e.preventDefault();
            this.$isLoading(true);
            // const valid = await this.$refs.contactForm.validate();
            // if (valid) {
            try {
                await this.$axios.post("sendMail", this.contact);
                this.contact.name = "";
                this.contact.email = "";
                this.contact.message = "";
                Swal.fire({
                    title: "Message Sent!",
                    icon: "success",
                });
                this.$isLoading(false);
            } catch (e) {
                this.$isLoading(false);
            }

            // }
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

.contact-input {
    border-radius: 9999px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

.contact-textarea {
    border-radius: 20px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

#contactMail {
    @include sm {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>

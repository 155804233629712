<template>
    <section>
        <b-card class="shadow-sm">
            <UserForm></UserForm>
        </b-card>
    </section>
</template>

<script>
import UserForm from "@/components/User/UserForm";

export default {
    name: "CreateUser",
    components: {UserForm},
}
</script>

<style scoped>

</style>

<template>
    <div class="d-flex flex-column align-items-center justify-content-center vh-100">
        <h1 class="text-9xl text-danger text-capitalize">Oops, page not found.</h1>
        <button @click="$router.go(-1)" class="primary-btn green text-6xl mt-4">GET BACK</button>
    </div>
</template>

<script>
export default {
    name: "Error"
}
</script>

<style scoped>

</style>

<template>
    <section>
        <b-card class="shadow-sm">
            <form @submit.prevent="save">
                <validation-observer ref="profileForm">
                    <div class="grid grid-cols-2 gap-x-6">
                        <b-form-group :label="$t('First Name *')">
                            <template #label>{{ $t('First Name') }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.first_name" rules="required"
                                            name="first name"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Last Name')">
                            <text-validator v-model="user.last_name" rules="required" name="last name"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Phone')">
                            <text-validator v-model="user.phone"  rules="required" name="phone"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Email')">
                            <template #label>{{ $t('Email') }} <span class="text-danger">*</span></template>
                            <text-validator v-model="user.email" rules="required" name="email"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('CRR ID')">
                            <text-validator v-model="user.customer_id_number" name="customer_id_number"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Customer No')">
                            <text-validator v-model="user.customer_number" name="customer_number"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Payer No')">
                            <text-validator v-model="user.payer_number" name="payer_number"></text-validator>
                        </b-form-group>
                        <b-form-group :label="$t('Address')" class="col-span-2">
                            <text-validator v-model="user.address" name="address"></text-validator>
                        </b-form-group>
                        <b-form-row>
                            <b-col sm="6" v-if="user.image">
                                <img class="userImage p-3" width="50%" :src="`${user.image.path}`" alt="">
<!--                                <b-form-group label="Change Image " class="col-span-2">-->
<!--                                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>-->
<!--                                </b-form-group>-->
                            </b-col>

<!--                            <b-col sm="6" v-else>-->
<!--                                <b-form-group label="Image " class="col-span-2">-->
<!--                                    <input type="file" id="file2" ref="file" v-on:change="handleFileUpload()"/>-->
<!--                                </b-form-group>-->

<!--                            </b-col>-->
                        </b-form-row>

                    </div>
                    <div class="text-right">
                        <button class="primary-btn green">{{ $t('Save') }}</button>
                    </div>
                </validation-observer>
            </form>
        </b-card>
    </section>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert, successAlert,validationAlert} from "../components/Theme/Alerts";

export default {
    name: "Profile",
    components: {TextValidator, ValidationObserver},
    data() {
        return {
            user: {},
            // file:[],
        }
    },
    methods: {
        async save(e) {
            e.preventDefault()
            const valid = await this.$refs.profileForm.validate();
            if (valid) {
                try {
                    const {data} = await this.$axios.put('user/' + this.user.id, this.user)
                    localStorage.setItem('user', JSON.stringify(data));
                    successAlert('profile ', 'updated')
                    // location.reload();
                    // this.$route.name !== 'profile' && this.$router.push('profile');
                } catch (e) {
                    console.log(e.response.status)
                    
                    if (e.response.data.status === 422 || e.response.status === 422) {
                        await validationAlert(e.response.data.errors)
                    } else {
                        await errorAlert('Something went wrong', 'server error')
                    }
                }
            }
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
    },
    created() {
        this.user = {...this.authUser}
    },
    computed: {
        authUser() {
            return JSON.parse(localStorage.getItem('user'));
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="sticky-top">
            <div class="ABC111">
                <b-form-group class="float-right w-15 mt-3 mr-2" >
                    <b-form-select
                        id="langSelect"
                        class="mr-2 text-uppercase"
                        size="sm"
                        v-model="language"
                        :options="languages"
                    ></b-form-select></b-form-group>
            </div>
            <Navbar></Navbar>
            <notification-bar></notification-bar>
            <breadcrumbs></breadcrumbs>
        </div>
        <page-header></page-header>
        <div class=" vScreen">
            <div class="grid grid-cols-6">
                <b-sidebar backdrop no-header id="slideSidebar">
                    <sidebar></sidebar>
                </b-sidebar>

                <sidebar class="d-none d-lg-block"></sidebar>
                <main class="col-span-5  mainSection" >
                    <router-view></router-view>
                </main>
            </div>
        </div>
        <footer class="py-2">
            <div class="text-center text-white">
                <div class="text-uppercase">{{ $t('contact_us') }}</div>
                <div class="mt-3">{{ $t('copy_rights_1') }} &copy; <span class="text-uppercase">{{ $t('copy_rights_2') }}</span>
                </div>
            </div>
        </footer>
    </div>
</template>


<script>
import Navbar from "@/components/Theme/Navbar";
import NotificationBar from "@/components/Theme/NotificationBar";
import Breadcrumbs from "@/components/Theme/Breadcrumbs";
import PageHeader from "@/components/Theme/PageHeader";
import Sidebar from "@/components/Theme/Sidebar";
import {mapActions} from "vuex";
import {i18n} from "@/plugins/i18n";
// import {invoiceOptions} from "@/constants";
// import {router} from "@/routes";

export default {
    name: "DefaultLayout",
    data() {
        return {
            languages: ["en", "ar"],
            language:this.systemLanguage(),
        }
    },
    components: {Sidebar, PageHeader, Breadcrumbs, NotificationBar, Navbar},
    mounted() {
        if (localStorage.getItem("language_selected") !== null ) {
            const htmlContainer = document.querySelector('html');
            const bodyContainer = document.querySelector('body');
            htmlContainer.setAttribute('lang', localStorage.getItem("language_selected"));
            bodyContainer.setAttribute('class', localStorage.getItem("language_selected"));
            const {dir} = i18n.getLocaleMessage(localStorage.getItem("language_selected"));

            htmlContainer.setAttribute('dir',dir);

        }
    },
    methods: {
        ...mapActions(["changeLanguage"]),
        makeToast(append = false) {
            this.$bvToast.toast(`You don't have permission to access this page.`, {
                title: 'Access Denied',
                autoHideDelay: 3000,
                appendToast: append,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
                solid: true
            })
        },
        systemLanguage () {
            if (localStorage.getItem("language_selected") === null) {
                return  this.$store.state.lang;
            }else{
                return  localStorage.getItem("language_selected");
            }
        }
    },


    watch: {
        language: function (val, old) {
            if (val !== old) {
                this.changeLanguage(val);
                i18n.locale = val;
                const {locale} = i18n
                const htmlContainer = document.querySelector('html');
                const bodyContainer = document.querySelector('body');
                const {dir} = i18n.getLocaleMessage(locale);
                bodyContainer.setAttribute('class', locale);
                htmlContainer.setAttribute('lang', locale);
                const currentDirection = htmlContainer.getAttribute('dir');

                if (currentDirection !== dir) {
                    htmlContainer.setAttribute('dir', dir)
                }


            }
        },
    },
    beforeRouteEnter(to, from, next) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            next({path: 'login'})
        } else {
            if (to.meta.permission) {
                if (user.permissions.includes(to.meta.permission)) {
                    next()
                    // next({path: 'home'})
                } else {
                    next(vm => {
                        vm.$router.push('/home')
                        vm.makeToast()
                    });
                }
            } else next()
        }
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

.vScreen{
    @include md{
        min-height: calc(100vh - 325px);
    }
}
//.container {
    .grid {
        @media screen and (max-width: 991px) {
            grid-template-columns:4fr;
        }
        main{
            padding: 0;
            @include md{
                padding-left: 1.5rem;
            }
        }
    }
//}

footer {
    font-size: 14px;
}

.mainSection{
    padding-left: 23px;
}
</style>

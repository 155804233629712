<template>
    <section id="navbar" class="bg-white">
        <div class="px-3">
            <!--        <div class="container">-->
            <b-navbar toggleable="lg" type="light" variant="transparent" class="px-0">
                <b-navbar-brand to="/home"><img src="../../assets/images/logo.png" alt=""></b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto text-uppercase">
<!--                        <b-nav-item>Home</b-nav-item>-->
<!--                        <b-nav-item>about</b-nav-item>-->
<!--                        <b-dropdown variant="link" text="CONTRACTS" id="navDropdown">-->
<!--                            <b-dropdown-item href="#">First</b-dropdown-item>-->
<!--                            <b-dropdown-item href="#">Second</b-dropdown-item>-->
<!--                        </b-dropdown>-->
<!--                        <b-dropdown variant="link" text="INVOICES">-->
<!--                            <b-dropdown-item :to="{name:'Invoices',params:{type:'due'}}">Due</b-dropdown-item>-->
<!--                            <b-dropdown-item :to="{name:'Invoices',params:{type:'overdue'}}">Overdue</b-dropdown-item>-->
<!--                            <b-dropdown-item :to="{name:'Invoices',params:{type:'paid'}}">Paid</b-dropdown-item>-->
<!--                            <b-dropdown-item :to="{name:'Invoices',params:{type:'undue'}}">Upcoming</b-dropdown-item>-->
<!--                        </b-dropdown>-->
<!--                        <b-nav-item>payments</b-nav-item>-->
<!--                        <b-nav-item>my account</b-nav-item>-->
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </section>
</template>

<script>
export default {
    name: "Navbar"
}
</script>

<style lang="scss">
@import "~@/assets/sass/_mixins.scss";


//#navbar.dropdown-toggle {
//    color: rgba(0, 0, 0, 0.5);
//    transition: color 0ms;
//
//}
#navbar {
    border-bottom: 3px solid #fcba29;

    .navbar-brand {
        img {
            width: 250px;
            @include md {
                width: 300px;
            }
        }
    }

    .dropdown-toggle {
        color: rgba(0, 0, 0, 0.5);
        transition: color 0ms;
        text-align: left;

        &:hover {
            color: rgba(#000, 0.7);
        }
    }
}
</style>

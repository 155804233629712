<template>
    <section>
        <div class="d-flex items-center justify-content-end arabicDirInit">
            <CustomerDropdown
                @filterCustomerId="filterActivity"
                v-can="'filter invoices'"
                class="mx-2"
            />
            <date-picker
                v-model="start_date"
                @change="getActivity"
                format="YYYY-MM-DD"
                type="date"
                :placeholder="$t('Due Date Start')"
                style="width: 150px" class="bg-white ml-2"
                v-can="'filter invoices'"
            ></date-picker>
            <date-picker
                style="width: 150px" class="bg-white ml-2"
                v-model="end_date"
                @change="getActivity"
                format="YYYY-MM-DD"
                type="date"
                :placeholder="$t('Due Date End')"
                v-can="'filter invoices'"
            ></date-picker>
        </div>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table head-variant="dark" class="text-center" small
                     :items="activities.data"
                     show-empty bordered
                     :fields="fields"
                     :busy="loading"
                     fixed
                     responsive
            >

                <template  #cell(created_at)="data">
                    <span v-if="data.item.created_at">{{ data.item.created_at | moment }}</span>
                </template>
                <template #table-busy>
                    <table-loader/>
                </template>
            </b-table>
            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="activities.total"
                @input="getActivity"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
        </b-card>
    </section>
</template>

<script>


import moment from "moment";
import CustomerDropdown from "@/components/User/CustomerDropdown";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import TableLoader from "@/components/tableLoader";
export default {
    name: "UserActivity",
    components: {
        CustomerDropdown,
        DatePicker,
        TableLoader,
    },
    computed:{
        fields() {
            return [
                // {
                //     label: this.$t('#'),
                //     key: 'id'
                // },
                {
                    label: this.$t('Action'),
                    key: 'log_name'
                },
                {
                    label: this.$t('User'),
                    key: 'user.name'
                },
                
                {
                    label: this.$t('Description'),
                    key: 'description'
                },
                {
                    label: this.$t('Date'),
                    key: 'created_at',
                },
            ]
        }
    },
    data() {
        return {
            currentPage: 1,

            activities: [], // table rows
            filterTag: '',
            customer_id: '',
            loading: false,
            start_date: null,
            end_date: null,
            customers: [],

        }
    },
    created() {
        this.getActivity()
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY h:mma");
        },
    },
    methods: {
        async getActivity() {
            try{
                this.loading=true;
                const start_date = this.start_date !== null ? moment(this.start_date).format("YYYY-MM-DD") : null;
                const end_date = this.end_date !== null ? moment(this.end_date).format("YYYY-MM-DD") : null;

                const {data} = await this.$axios.get(
                    `activity_log?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&page=${this.currentPage}`
                );
                this.activities = data;
                this.loading=false;
            } catch (err) {
                this.loading=false;
            }

        },

        filterActivity(value) {
            this.customer_id = value;
            this.getActivity();
        }
    },

}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

.contact-input {
    border-radius: 9999px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

.contact-textarea {
    border-radius: 20px;
    padding: 20px 15px;
    background: rgba(#000, 0.1);
}

#contactMail {
    @include sm {
        grid-template-columns: repeat(2, 1fr);

    }
}
</style>

<template>
    <div>
        <h3 class="text-3xl">{{$t('Department')}}</h3>
        <form @submit.prevent="save" class="mt-md-3">
            <validation-observer ref="departmentForm">
                <b-form-group :label="$t('Name')">
                    <text-validator v-model="department.name" name="name" rules="required"></text-validator>
                </b-form-group>
                <div class="text-right">
                    <button class="primary-btn green">{{ $t('Save') }}</button>
                </div>
            </validation-observer>
        </form>
    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert, successAlert} from "@/components/Theme/Alerts";

export default {
    name: "DepartmentForm",
    components: {TextValidator, ValidationObserver},
    props: ['formType', 'departmentProp'],
    data() {
        return {
            department: {
                name: ''
            }
        }
    },
    created() {
        if (this.formType === 'edit') {
            this.department = {...this.departmentProp}
        }
    },
    methods: {
        async save() {
            const valid = await this.$refs.departmentForm.validate()
            if (valid) {
                let url, method;
                const data = this.department
                if (this.formType === 'add') {
                    url = `department`
                    method = 'post'
                } else {
                    url = `department/${data.id}`;
                    method = 'put'
                }
                try {
                    await this.$axios[method](url, data);
                    await successAlert('Department', this.formType === 'add' ? 'created' : 'updated');
                    this.$emit('close')
                } catch (e) {
                    await errorAlert('Department', this.formType === 'add' ? 'creating' : 'updating')
                }
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <h3 class="text-3xl arabicDir">{{$t('Select preferred alerts')}}</h3>
        <form action="" class="mt-4 arabicDir">
            <b-form-group>
                <b-form-checkbox-group
                    v-model="selected"
                    :options="options"
                    switches
                    stacked
                ></b-form-checkbox-group>
            </b-form-group>
        </form>
    </div>
</template>

<script>
export default {
    name: "NotificationSettings",
    data() {
        return {
            selected: [], // Must be an array reference!
            options: [
                {text: this.$t('Receive email for due invoices'), value: 'red'},
            ]
        }
    }
}
</script>

<style scoped>

</style>

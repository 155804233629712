<template>
    <ValidationProvider mode="passive" :rules="rules" :name="name" v-slot="{ errors }">
        <b-input
            :disabled="disabled"
            :size="size"
            :type="type"
            :value="value"
            @input="changeValue"
            :formatter="isNumber"
            :class="{'disable': disabled}"
            :state="errors.length>0 ? false: null"
        />
        <span class="text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import './rules'

export default {
    name: "TextValidator",
    components: {
        ValidationProvider,
    },
    props: {
        value: {
            default: "",
        },
        rules: {
            type: [String, Object],
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        size: {
            type: String,
            default: "md",
        },
        disabled: {
            type: Boolean,
        },
    },
    methods: {
        changeValue(value) {
            this.$emit("input", value);
        },
        changeVal(val) {
            this.$emit("change", val);
        },
        isNumber(val) {
            if (this.type === 'number') {
                if (/^[0-9]+$/.test(val)) {
                    return val
                }
            } else {
                return val
            }
        },
    },
};
</script>
<style scoped>
.disable {
    cursor: not-allowed;
}
</style>

<template>
    <section class="bg-dark py-2">
        <div class="px-3">
            <!--        <div class="container">-->
            <div class="grid grid-cols-2 text-light items-center">
                <div class="text-uppercase arabicDir">{{ $t('account_management_control_panel') }}</div>
                <div class="d-flex justify-self-end">
                    <!-- <router-link to="/cart" class="notification-icon text-white">
                        <b-icon font-scale="1.4" class="mb-0" icon="cart"></b-icon>
                        <b-badge class="rounded-circle border font-weight-bold" href="#" variant="warning">
                            {{ cart.length }}
                        </b-badge>
                    </router-link> -->
<!--                    <div class="notification-icon">
                        <b-icon font-scale="1.4" class="mb-0 ml-3" icon="bell"></b-icon>
                        <b-badge class="rounded-circle border font-weight-bold" href="#" variant="warning">7</b-badge>
                    </div>-->
<!--                    <div class="notification-icon">
                        <b-icon font-scale="1.4" class="mb-0 ml-3" icon="envelope"></b-icon>
                        <b-badge class="rounded-circle border font-weight-bold" href="#" variant="warning">2</b-badge>
                    </div>-->
                    <b-dropdown variant="link" toggle-class="p-0 text-white notification-dropdown">
                        <template #button-content>
                            <b-icon font-scale="1.4" class="mb-0 ml-3" icon="person"></b-icon>
                        </template>
                        <b-dropdown-item to="profile">{{ $t('profile') }}</b-dropdown-item>
                        <b-dropdown-item @click.prevent="logout" v-if="loggedIn">{{ $t('logout') }}</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {serverErrorAlert} from "@/components/Theme/Alerts";
import {mapGetters} from "vuex";

export default {
    name: "NotificationBar",
    methods: {
        async logout() {
            try {
                const user = {
                    user:  this.authUser,
                }
                await this.$axios.post('logout',user);
                localStorage.removeItem('user');
                this.$router.push('/login');
            } catch (e) {
                serverErrorAlert()
            }
        },
    },

    computed: {
        authUser() {
            return JSON.parse(localStorage.getItem('user'));
        },
        loggedIn() {
            return Object.keys(JSON.parse(localStorage.getItem('user'))).length > 0
        },
        ...mapGetters(['cart'])
    }
}
</script>

<style scoped lang="scss">
.notification-icon {
    position: relative;

    .badge {
        font-size: 9px;
        position: absolute;
        top: -4px;
        right: -5px;
    }
}

.notification-dropdown {
    box-shadow: none;
    outline: none;
}
</style>

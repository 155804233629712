import Swal from 'sweetalert2'

export const serverErrorAlert = () => {
    return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        showConfirmButton: false,
        footer: "There might be some problem with the server.",
    });
}
export const confirmDeleteAlert = (item) => {
    return Swal.fire({
        icon: 'info',
        title: `Confirm deleting ${item}`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
    });
}
export const confirmCancelAlert = (item) => {
    return Swal.fire({
        icon: 'info',
        title: `Confirm Canceling ${item}`,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
    });
}
export const confirmOptionAlert = (item) => {
    return Swal.fire({
        icon: 'info',
        title: `${item}`,
        confirmButtonText: 'Cancele Existing & Generate New',
        showCancelButton: true,
        cancelButtonText: 'Close',
    });
}
export const confirmReverseAlert = (item) => {
    return Swal.fire({
        icon: 'info',
        title: `${item}`,
        confirmButtonText: 'Reverse this payment',
        showCancelButton: true,
        cancelButtonText: 'Close',
    });
}
export const successAlert = (item, action = 'saved') => {
    return Swal.fire({
        icon: 'success',
        title: `${item} ${action}`
    })
}

export const errorAlert = (title = '', text = '') => {
    return Swal.fire({
        icon: "error",
        title,
        text,
        showConfirmButton: false,
    });
}

export const validationAlert = (messages = []) => {
    let html = `<ul class="list-unstyled">${Object.keys(messages).map(message => {
        return messages[message].map(msg => `<li>${msg}</li>`)
    })}</ul>`
    return Swal.fire({
        html: html,
        icon: "error"
    })
}

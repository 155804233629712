import Vue from 'vue';
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout";
import Home from "@/pages/Home";
import Error from "@/pages/Error";
import Login from "@/pages/Login";
import TwoFactorAuthentication from "@/pages/TwoFactorAuthentication";
import ForgotPassword from "@/pages/passwordReset/ForgotPassword";
import support from "@/pages/support";
import UserActivity from "@/pages/UserActivity";
import Revisions from "@/pages/Revisions";


Vue.use(VueRouter);

const files = require.context('./pages', true, /^(?!.*(?:Login|ForgotPassword|TwoFactorAuthentication)).*\.vue$/);

let pages = {};
files.keys().map(key => {
    pages[key.split('/').pop().split('.')[0]] = {
        component: files(key).default,
        path: key.split('/').pop().split('.')[0]
    }
})
// const invoicePermissions = {
//     due: '',
//     undue: '',
//     paid: '',
//     overdue: ''
// }

pages = {
    ...pages,
    Users: {
        ...pages.Users,
        meta: {
            permission: 'view users'
        }
    },
    EditUser: {
        ...pages.EditUser,
        path: pages.EditUser.path += '/:id',
        meta: {
            permission: 'edit user'
        }
    },
    EditInvoice: {
        ...pages.EditInvoice,
        path: pages.EditInvoice.path += '/:id'
    },
    Invoices: {
        ...pages.Invoices,
        path: pages.Invoices.path += '/:type?',
        meta: {
            permission: 'view invoices'
        }
    },
    Payments: {
        ...pages.Payments,
        path: pages.Payments.path += '/:id?',
        meta: {
            permission: 'view payments'
        }
    },
    Contracts: {
        ...pages.Contracts,
        path: pages.Contracts.path += '/:type?'
    },
    ContractDetails: {
        ...pages.ContractDetails,
        path: pages.ContractDetails.path += '/:id?'
    },
    SadadTransactionsInvoices: {
        ...pages.SadadTransactionsInvoices,
        path: pages.SadadTransactionsInvoices.path += '/:id?'
    },
    DepartmentList: {
        ...pages.DepartmentList,
        meta: {
            permission: 'view departments'
        }
    }
}//
// console.log(pages)
export const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'DefaultLayout',
            component: DefaultLayout,
            children: [
                {
                    path: '/',
                    component: Home,
                    name: 'Home',
                },
                {
                    path: '/support',
                    component: support,
                    name: 'Support',
                },
                {
                    path: '/useractivity',
                    component: UserActivity,
                    name: 'UserActivity',
                },
                {
                    path: '/revisions',
                    component: Revisions,
                    name: 'Revisions',
                },
                ...Object.keys(pages).map((page) => {
                    return {
                        path: (pages[page].path.replace(/(\D)([A-Z])/g, "$1-$2")).toLowerCase(),
                        name: page,
                        component: pages[page].component,
                        meta: pages[page].meta,
                    }
                })
            ]
        },
        {
            path: '/login',
            component: Login,
            name: 'login'
        },
        {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword
        },
        {
            path: '/two-factor-authentication',
            name: 'TwoFactorAuthentication',
            component: TwoFactorAuthentication
        },
        {
            path: '/',
            component: Home
        },
        {path: "*", component: Error}
    ]
})

<template>
    <section>
        <div class="accordion" role="tablist">
            <b-card v-for="(faq,index) in faqs" :key="faq.id" no-body class="accordion-item">
                <b-card-header header-tag="div" class="collapse-header" role="tab">
                    <b-button class="collapse-button" block     @click="toggleCollapse(`collapse-${index}`)" variant="transparent">{{faq.question}}
                    </b-button>
                </b-card-header>
                <b-collapse class="collapse-body" :id="`collapse-${index}`" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <p>{{faq.answer}}</p>
                    </b-card-body>
                </b-collapse>
            </b-card>
<!--            <b-card no-body class="accordion-item">-->
<!--                <b-card-header header-tag="div" class="collapse-header" role="tab">-->
<!--                    <b-button class="collapse-button" block v-b-toggle.accordion-2 variant="transparent">How the-->
<!--                        invoices are paid?-->
<!--                    </b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse class="collapse-body" id="accordion-2" visible accordion="my-accordion" role="tabpanel">-->
<!--                    <b-card-body>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ducimus eos fugit minus-->
<!--                            nulla officia perferendis quibusdam saepe tempora velit? Cumque ipsam laboriosam laborum-->
<!--                            minima, neque nesciunt quibusdam similique vitae?</p>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
<!--            <b-card no-body class="accordion-item">-->
<!--                <b-card-header header-tag="div" class="collapse-header" role="tab">-->
<!--                    <b-button class="collapse-button" block v-b-toggle.accordion-3 variant="transparent">How to contact-->
<!--                        support?-->
<!--                    </b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse class="collapse-body" id="accordion-3" visible accordion="my-accordion" role="tabpanel">-->
<!--                    <b-card-body>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ducimus eos fugit minus-->
<!--                            nulla officia perferendis quibusdam saepe tempora velit? Cumque ipsam laboriosam laborum-->
<!--                            minima, neque nesciunt quibusdam similique vitae?</p>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
<!--            <b-card no-body class="accordion-item">-->
<!--                <b-card-header header-tag="div" class="collapse-header" role="tab">-->
<!--                    <b-button class="collapse-button" block v-b-toggle.accordion-4 variant="transparent">Where are the-->
<!--                        user guides?-->
<!--                    </b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse class="collapse-body" id="accordion-4" visible accordion="my-accordion" role="tabpanel">-->
<!--                    <b-card-body>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ducimus eos fugit minus-->
<!--                            nulla officia perferendis quibusdam saepe tempora velit? Cumque ipsam laboriosam laborum-->
<!--                            minima, neque nesciunt quibusdam similique vitae?</p>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
<!--            <b-card no-body class="accordion-item">-->
<!--                <b-card-header header-tag="div" class="collapse-header" role="tab">-->
<!--                    <b-button class="collapse-button" block v-b-toggle.accordion-5 variant="transparent">How can I-->
<!--                        pay?-->
<!--                    </b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse class="collapse-body" id="accordion-5" visible accordion="my-accordion" role="tabpanel">-->
<!--                    <b-card-body>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ducimus eos fugit minus-->
<!--                            nulla officia perferendis quibusdam saepe tempora velit? Cumque ipsam laboriosam laborum-->
<!--                            minima, neque nesciunt quibusdam similique vitae?</p>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
<!--            <b-card no-body class="accordion-item">-->
<!--                <b-card-header header-tag="div" class="collapse-header" role="tab">-->
<!--                    <b-button class="collapse-button" block v-b-toggle.accordion-6 variant="transparent">Are there any-->
<!--                        hidden charges?-->
<!--                    </b-button>-->
<!--                </b-card-header>-->
<!--                <b-collapse class="collapse-body" id="accordion-6" visible accordion="my-accordion" role="tabpanel">-->
<!--                    <b-card-body>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ducimus eos fugit minus-->
<!--                            nulla officia perferendis quibusdam saepe tempora velit? Cumque ipsam laboriosam laborum-->
<!--                            minima, neque nesciunt quibusdam similique vitae?</p>-->
<!--                    </b-card-body>-->
<!--                </b-collapse>-->
<!--            </b-card>-->
        </div>
    </section>
</template>

<script>
export default {
    name: "faqs",
    data(){
        return {
            faqs:[],
        }
    },
    methods:{
        async getFaqs() {
            const {data} = await this.$axios.get('getFaqs');
            this.faqs = data;

        },
        toggleCollapse(id) {
            this.$root.$emit('bv::toggle::collapse', id)
        },
    },
    created() {
        this.getFaqs();
        console.log('done');
        console.log(this.faqs , 'sdfsdf');

    }

}
</script>

<style scoped lang="scss">
.accordion-item {
    border-radius: 0 !important;
    box-shadow: 0px 0px 2px rgba(#000, 0.5);
    @extend .mb-4
}

.collapse-header {
    padding: 0;
}

.collapse-body {
    background: rgba(#ffcccc, 0.2);
}

.collapse-button {
    border-radius: 0 !important;
    box-shadow: none !important;
    text-align: left;
    padding: 12px;
    background: #fff;
}
</style>

<template>
    <div>
        <h1 class="py-3 text-capitalize">SADAD # {{this.$route.params.id }}</h1>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table head-variant="dark" small
                     :items="items.data"
                     show-empty bordered
                     :fields="fields"
                     :tbody-tr-class="rowClass"
                     class="text-center"
                     responsive
            >
                <template #cell(amount)="data">
                    {{ data.item.amount | comma_separated }}
                </template>
                <template #cell(gate_way_transactions.issue_date)="data">
                    {{ data.item.gate_way_transactions.issue_date | moment }}
                </template>
                <template #cell(gate_way_transactions.expire_date)="data">
                    {{ data.item.gate_way_transactions.expire_date | moment }}
                </template>
            </b-table>

            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="items.total"
                @input="getSadadTransactionsInvoices"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>

        </b-card>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "SadadTransactionsInvoices",
    computed:{
        fields() {
            return [
                {
                    label: this.$t('SADAD#'),
                    key: 'sadad_number'
                },
                {
                    label: this.$t('Invoice'),
                    key: 'invoices.number'
                },
                {
                    label: this.$t('Contracts'),
                    key: 'erp_contract_id'
                },
                {
                    label: this.$t('Customer'),
                    key: 'user.name'
                },
                {
                    label: this.$t("Issue Date"),
                    key: "gate_way_transactions.issue_date",
                },
                {
                    label: this.$t("Expiry Date"),
                    key: "gate_way_transactions.expire_date",
                },
                {
                    label: this.$t('Payment Method'),
                    key: 'payment_method.name'
                },
                {
                    label: this.$t('Amount'),
                    key: 'amount'
                },
                {
                    label: this.$t('Status'),
                    key: 'status'
                },
            ]
        }
    },
    data() {
        return {

            items: [],
            currentPage: 1,
            statusColor: {
                paid: 'table-success',
                pending: 'table-warning',
                canceled: 'table-danger',
                expired: 'table-secondary',
            },

        }
    },
    created() {
        if(this.$route.params.id){
        this.getSadadTransactionsInvoices(this.$route.params.id);
        }else{
            this.$router.push('/sadad-transactions');
        }
    },
    methods: {
        async getSadadTransactionsInvoices() {
            const {data} = await this.$axios.get(`sadadTransactionsInvoices?sadad_number=${this.$route.params.id}&currentPage=${this.currentPage}`);
            this.items = data;
            //duplicate code
            if (this.$route.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = this.$route.params.type;
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            return this.statusColor[item.status]
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = to.params.type;
            }
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div class="d-flex items-center vh-100">
        <div id="loginFrame" class="mx-auto">
            <div class="text-center mb-4">
                <!--                <img class="w-50" src="../assets/images/logo.png" alt="">-->
            </div>
            <b-card>
                <div class=" grid text-5xl font-weight-light text-muted">Reset Password</div>
                <form @submit="resetPassword" action="" method="post" class="mt-4 w-75 mx-auto">
                    <validation-observer ref="loginForm">

                        <b-form-group class="col-md-12" label="New Password *">
                            <b-form-input type="password" v-model="resetData.password" required
                                          placeholder=""></b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-12" label="Confirm New Password *">
                            <b-form-input type="password" v-model="resetData.password_confirmation" required
                                          placeholder=""></b-form-input>
                        </b-form-group>

                        <b-form-group class="mt-4" label-cols="12">
                            <button class="primary-btn green">Submit</button>
                            <div class="mt-2">
                            </div>
                        </b-form-group>
                    </validation-observer>
                </form>
            </b-card>
        </div>
    </div>
</template>
<script>
import {ValidationObserver} from "vee-validate";
import {errorAlert, successAlert} from "@/components/Theme/Alerts";

export default {
    name: "ResetPassword",
    components: {ValidationObserver},
    data() {
        return {
            resetData: {
                token: '12345678',
                password: '',
                password_confirmation: ''
            }
        }
    },
    methods: {
        async resetPassword(e) {
            e.preventDefault()
            // const valid = await this.$refs.loginForm.validate();
            // if (valid) {
            try {

                const {data} = await this.$axios.post(`resetPassword`, this.resetData)
                await successAlert('Password updated', 'Successfully')
                console.log(data)
            } catch (e) {
                errorAlert('Error', 'Something went wrong')
            }
            // }
        }
    },
    created() {
        this.password.token = this.$route.params.token;
    }
}
</script>

<style scoped>

</style>

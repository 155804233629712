<template>
    <div>
        <section>
            <Stats
                @change="filterHandler"
                :allow-filter="true"
                :type="type"
                :hidden="['total_amount']"
                :invoices-total="invoicesTotal"
            />
        </section>

        <div class="mt-4">
            <div class="d-flex justify-content-between">
                <h3 class="text-capitalize">
                    {{
                        this.type.length === 1
                            ? $t(`${this.type.join()} Invoices`)
                            : $t("Invoices")
                    }}
                </h3>
                <div class="d-flex items-center justify-content-end">
                    <CustomerDropdown
                        @filterCustomerId="filterInvoices"
                        v-can="'filter invoices'"
                        class="mx-2"
                    />
                    <date-picker
                        v-model="start_date"
                        @change="getInvoices"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Due Date Start')"
                        style="width: 150px"
                        class="bg-white ml-2"
                        v-can="'filter invoices'"
                    ></date-picker>
                    <date-picker
                        style="width: 150px"
                        class="bg-white ml-2"
                        v-model="end_date"
                        @change="getInvoices"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Due Date End')"
                        v-can="'filter invoices'"
                    ></date-picker>
                    <b-input
                        style="width: 200px"
                        class="bg-white ml-2"
                        v-model="search"
                        @keyup="getInvoicesBySearch"
                        @blur="getInvoices"
                        :placeholder="$t('Search by invoice/contract')"
                        v-can="'filter invoices'"
                    ></b-input>
                    <export-excel
                        class="btn px-3 ml-2 pr-2 btn-info"
                        :fetch="fetchDataToExport"
                        :fields="excel_export_fields_name"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        worksheet="Invoices"
                        v-can="'filter invoices'"
                        :name="
                            'invoices-' +
                            Math.floor(Math.random() * 100) +
                            '-.xls'
                        "
                    >
                        {{ $t("Export To Excel") }}
                    </export-excel>
                    <span class="font-weight-semi-bold ml-2 mr-3"
                        >{{ $t("Total Amount") }}:
                        {{
                            parseFloat(invoicesTotal.total_amount).toFixed(2)
                                | comma_separated
                        }}
                        SAR</span
                    >
                </div>
            </div>
            <div class="grid grid-cols-3 gap-x-4 mt-4">
                <div :class="[openCheckout ? 'col-span-2' : 'col-span-3']">
                    <b-table
                        :busy="loading"
                        class="text-center"
                        head-variant="dark"
                        small
                        :items="invoices.data"
                        :tbody-tr-class="rowClass"
                        show-empty
                        bordered
                        :fields="visibleFields"
                        fixed
                        responsive
                    >
                        <template #table-busy>
                            <table-loader />
                        </template>
                        <template #cell(pay)="data">
                            <div class="text-center">
                                <b-form-checkbox class="payCheckbox" v-if="data.item.status != 'paid'" :checked="hasChecked(data.item.id, 'invoices')"
                                    @change="(checked) => checkoutHandler(checked,data.item,'invoices')">
                                </b-form-checkbox>
                            </div>
                        </template>
                        <template #cell(sadad_invoice)="data">
                            <b-link
                                v-if="data.item.sadad_invoice"
                                class="text-primary"
                                :to="`/sadad-transactions-invoices/${data.item.sadad_invoice.sadad_number}`"
                            >
                                {{ data.item.sadad_invoice.sadad_number }}<br />
                                {{ data.item.sadad_invoice.amount }} SAR/{{
                                    data.item.sadad_invoice.status
                                }}
                            </b-link>
                        </template>
                        <template #cell(total)="data">
                            {{ data.item.total | comma_separated }}
                        </template>
                        <template #cell(amount)="data">
                            {{ data.item.amount | comma_separated }}
                        </template>
                        <template #cell(balance)="data">
                            {{ data.item.balance | comma_separated }}
                        </template>
                        <template #cell(paid_date)="data">
                            <span v-if="data.item.payments[data.item.payments.length - 1]">
                                    <b-link v-if="data.item.payments[data.item.payments.length - 1].paid_date"
                                        class="text-primary"
                                        :to="`/payments/${data.item.id}`"
                                    >
                                    {{
                                        data.item.payments[data.item.payments.length - 1].paid_date | moment
                                    }}
                                </b-link>
                            </span>
                            
                        </template>
                        <template #cell(installment.created_at)="data">
                            {{ data.item.installment.created_at | moment }}
                        </template>
                        <template #cell(installment.due_date)="data">
                            {{ data.item.installment.due_date | moment }}
                        </template>
                        <template #cell(status)="data">
                            {{
                                data.item.status == "undue"
                                    ? "upcoming"
                                    : data.item.status
                            }}
                        </template>
                        <template #cell(installment.contract.number)="data">
                            <b-link
                                v-if="data.item.installment"
                                class="text-primary"
                                :to="`/contract-details/${data.item.installment.contract.number}`"
                            >
                                {{ data.item.installment.contract.number }}
                            </b-link>
                        </template>

                        <template #cell(pay_amount)="data">
                            <InvoicePayAmount
                                :data="data"
                                :name="name"
                                v-if="data.item.status != 'paid'"
                                :checkedInvoices="checkedInvoices"
                                @getBackCheckedInvoices="updateInvoices"
                            ></InvoicePayAmount>
                            <!-- <b-button
                                v-if="data.item.status == 'paid'"
                                @click="revese_payment(data.item)"
                                class="mt-2"
                                variant="danger"
                            >
                                {{ $t("Reverse") }}
                            </b-button> -->
                        </template>
                    </b-table>
                </div>
                <InvoiceCheckoutList
                    @cancelPayment="cancelPayment"
                    @paid="clearCheckout"
                    v-if="openCheckout"
                    :invoices="checkedInvoices"
                    arrayName="invoices"
                    :name="name"
                    @getCheckoutHandler="checkoutHandler"
                    @getBackCheckedInvoicesFunc="updateInvoices"
                />
            </div>
            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="invoices.total"
                @input="getInvoices"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
            <!--            <div v-if="!type.includes('undue')&& !type.includes('paid')" class="text-right px-3 mb-3">-->
            <!--                <button  class="primary-btn green text-capitalize">-->
            <!--                    <b-icon icon="credit-card"></b-icon>-->
            <!--                    make payment-->
            <!--                </button>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Stats from "@/components/Stats";
import TableLoader from "@/components/tableLoader";
import CustomerDropdown from "@/components/User/CustomerDropdown";
import InvoiceCheckoutList from "@/components/Invoice/InvoiceCheckoutList";
import checkoutMixin from "@/mixins/checkoutMixin";
import InvoicePayAmount from "@/components/Invoice/InvoicePayAmount";
import isEmpty from "lodash.isempty";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
    confirmReverseAlert,
    errorAlert,
    successAlert,
} from "@/components/Theme/Alerts";
// import axios from "axios";
// import isEmpty from "lodash.isempty";

export default {
    name: "Invoices",

    mixins: [checkoutMixin],
    computed: {
        fields() {
            return [
                {
                    label: "#",
                    key: "pay",
                    visible: true,
                    thStyle: {
                        width: "30px",
                    },
                },
                {
                    label: this.$t("Contract"),
                    key: "installment.contract.number",
                    visible: true,
                    thStyle: {
                        width: "80px",
                    },
                },
                {
                    label: this.$t("Payer No"),
                    key: "user.payer_number",
                    visible: true,
                    thStyle: {
                        width: "60px",
                    },
                },
                {
                    label: this.$t("Customer"),
                    key: "user.name",
                    visible: true,
                    thStyle: {
                        width: "60px",
                    },
                },
                {
                    label: this.$t("Department"),
                    key: "installment.contract.category.name",
                    visible: this.hide_columns,
                    thStyle: {
                        width: "70px",
                    },
                },
                {
                    label: this.$t("Invoice"),
                    key: "number",
                    visible: true,
                    thStyle: {
                        width: "60px",
                    },
                },
                
                
                {
                    label: this.$t("Invoice Date"),
                    key: "installment.created_at",
                    visible: this.hide_columns,
                    thStyle: {
                        width: "70px",
                    },
                },
                {
                    label: this.$t("Due Date"),
                    key: "installment.due_date",
                    visible: true,
                    thStyle: {
                        width: "70px",
                    },
                },
                
                {
                    label: this.$t("Paid Date"),
                    key: "paid_date",
                    visible: true,
                    thStyle: {
                        width: "70px",
                    },
                },
                {
                    label: this.$t("Total"),
                    key: "total",
                    visible: true,
                    thStyle: {
                        width: "50px",
                    },
                },
                {
                    label: this.$t("Paid Amt"),
                    key: "amount",
                    visible: true,
                    thStyle: {
                        width: "50px",
                    },
                },
                {
                    label: this.$t("Balance"),
                    key: "balance",
                    visible: true,
                    thStyle: {
                        width: "50px",
                    },
                },
                {
                    label: this.$t("Status"),
                    key: "status",
                    visible: true,
                    thStyle: {
                        width: "50px",
                    },
                },
                {
                    label: this.$t("SADAD#"),
                    key: "sadad_invoice",
                    visible: true,
                    thStyle: {
                        width: "70px",
                    },
                },
                // {
                //     label: this.$t("Installment Ref."),
                //     key: "installment.ref_number",
                //     visible: this.hide_columns,
                //     thStyle: {
                //         width: "60px",
                //     },
                // },
                
              
                {
                    label: this.$t("Pay Amount"),
                    key: "pay_amount",
                    visible: true,
                    thStyle: {
                        width: "70px",
                    },
                },
            ];
        },
        visibleFields() {
            // console.log(this.fields.filter((field) => !field.visible))
            return this.fields.filter((field) => field.visible);
        },
    },

    data() {
        return {
            hide_columns: true,
            filterArray: [], //filter tags array
            invoices: [], // table rows
            type: [], //invoice subtype name
            filterChecked: 0,
            currentPage: 1,
            invoicesTotal: {},
            customers: [],
            customer_id: "",
            statusColor: {
                paid: "table-success",
                due: "table-warning",
                overdue: "table-danger",
                undue: "table-secondary",
            }, // Row Highlight Color
            openCheckout: false,
            loading: false,
            checkedInvoices: {},
            name: "invoices", //used as  array name in InvoicePayAmount Component
            /*export*/
            start_date: null,
            end_date: null,
            search: null,
            invoicesExport: [], // table rows
            enableExport: false,
            excel_export_fields_name: {
                "Invoice#": "number",
                Customer: "user.name",
                "Contract#": "installment.contract.number",
                "Invoice Date": {
                    field: "installment.created_at",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                "Due Date": {
                    field: "installment.due_date",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY");
                    },
                },
                Status: "status",
                "Sadad#": "sadad_invoice.sadad_number",
                "Paid Amt": "amount",
                Total: "total",
                Balance: "balance",
                "Installment Ref.": "installment.ref_number",
            },
        };
    },
    components: {
        CustomerDropdown,
        Stats,
        InvoiceCheckoutList,
        InvoicePayAmount,
        DatePicker,
        TableLoader,
    },
    created() {
        if (this.$route.params.type) this.type = [this.$route.params.type];
        this.getInvoices();
        this.getValues();
    },
    methods: {
        async revese_payment(item) {
            console.log(item);
            const { isConfirmed } = await confirmReverseAlert(
                "Please confirm if you want to reverse this payment!"
            );
            if (isConfirmed) {
                let data = {
                    invoice_id: item.id,
                };

                let payment_reverse = await this.$axios.post(
                    "payment_reverse",
                    data
                );
                if (payment_reverse.data.status_code == "422") {
                    errorAlert(payment_reverse.data.message, "Error");
                } else {
                    successAlert(payment_reverse.data.message, "Successfully");
                    this.getInvoices();
                    this.getValues();
                }
            }
        },
        async fetchDataToExport() {
            const start_date =
                this.start_date !== null
                    ? moment(this.start_date).format("YYYY-MM-DD")
                    : null;
            const end_date =
                this.end_date !== null
                    ? moment(this.end_date).format("YYYY-MM-DD")
                    : null;
            const search =
                this.search !== null
                    ? encodeURIComponent(this.search.trim())
                    : null;
            const { data } = await this.$axios.get(
                `invoice?customer_id=${
                    this.customer_id
                }&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${
                    this.currentPage
                }&type=${JSON.stringify(this.type)}&export=1`
            );
            return data.data;
        },
        startDownload() {
            console.log("show loading");
        },
        finishDownload() {
            console.log("hide loading");
        },
        hasChecked(id, arr) {
            // let skip_columns = [
            //     "installment.contract.category.name",
            //     "installment.ref_number",
            //     "installment.created_at",
            // ];
            if (!isEmpty(this.checkedInvoices[arr])) {
                this.hide_columns = false;
                // this.fields.map(
                //     (index) =>
                //         (index.visible = skip_columns.includes(index.key) ? false : true)
                // );
                return this.checkedInvoices[arr].some((inv) => inv.id === id);
            } else {
                this.hide_columns = true;
                this.fields.map((index) => (index.visible = true));
                return false;
            }
        },
        getInvoicesBySearch() {
            if (this.search.length >= 3) {
                this.getInvoices();
            }
        },
        async getInvoices() {
            try {
                this.loading = true;
                const start_date =
                    this.start_date !== null
                        ? moment(this.start_date).format("YYYY-MM-DD")
                        : null;
                const end_date =
                    this.end_date !== null
                        ? moment(this.end_date).format("YYYY-MM-DD")
                        : null;
                const search =
                    this.search !== null
                        ? encodeURIComponent(this.search.trim())
                        : null;
                const { data } = await this.$axios.get(
                    `invoice?customer_id=${
                        this.customer_id
                    }&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${
                        this.currentPage
                    }&type=${JSON.stringify(this.type)}`
                );
                this.invoices = data;
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        async getValues() {
            const { data } = await this.$axios.get("invoicesTotal");
            this.invoicesTotal = data;
        },
        rowClass(item, type) {
            if (!item || type !== "row") return;
            return this.statusColor[item.status];
        },
        filterHandler(checked, status) {
            // console.log(checked, status);
            if (checked) {
                this.type = [...this.type, status];
            } else {
                this.type = this.type.filter((item) => item !== status);
            }
            this.getInvoices();
        },
        filterInvoices(value) {
            this.customer_id = value;
            this.getInvoices();
        },
        clearCheckout() {
            // let x, i;
            // x = document.querySelectorAll(".payCheckbox");
            // for (i = 0; i < x.length; i++) {
            //     x[i].style.value ="false";
            // }
            this.openCheckout = false;
            this.checkedInvoices = {};
            this.getInvoices();
        },
        updateInvoices(invoices) {
            // console.log('updateInvoices(invoices)',invoices,'=============setp1==================>');
            this.checkedInvoices = invoices;
        },
        cancelPayment() {
            this.checkedInvoices = {};
            this.openCheckout = false;
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
    watch: {
        $route(to) {
            this.type = to.params.type ? [to.params.type] : [];
            this.getInvoices();
        },
    },
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#invocieFilter {
    grid-template-columns: repeat(2, 1fr);
    //& > div {
    //      @extend .py-3;
    //
    //&:not(:last-child) {
    //     border-bottom: 1px solid #eee;
    // }
    //}

    @include lg {
        grid-template-columns: repeat(4, 1fr);
        //border-bottom: none;

        //& > div {
        //&:not(:first-child) {
        //     border-left: 1px solid #eee;
        // }
        //    border-bottom-color: transparent !important;
        //}
    }
}
</style>

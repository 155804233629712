<template>
    <ValidationProvider mode="passive" :rules="rules" :name="name" v-slot="{ errors }">
        <v-select
            :options="options"
            :label="label"
            :value="value"
            :multiple="multiple"
            @input="changeValue"
            v-bind:reduce="reduce ? item => item[valueField] : item => item"
            :disabled="disabled"
            :class="{'error-select':errors.length> 0}"
        >
        </v-select>
        <span class="text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import VSelect from "vue-select";

import {ValidationProvider} from "vee-validate";

export default {
    name: "SelectValidator",
    components: {
        ValidationProvider,
        VSelect
    },
    props: {
        value: {
            type: [Number, String, Array],
            default: 0,
        },
        rules: {
            type: [String, Object],
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        size: {
            type: String,
            default: "lg",
        },
        options: {
            type: Array,
            default: function () {
                return [];
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: 'title'
        },
        valueField: {String, default: 'id'},
        reduce: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeValue(value) {
            this.$emit("input", value);
        },
    },
};
</script>
<style lang="css">
.error-select .vs__dropdown-toggle {
    border: 1px solid #dc3545;
}
</style>

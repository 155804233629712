<template>
    <div>
        <section>
            <b-card>
                <invoice-form></invoice-form>
            </b-card>
        </section>
    </div>
</template>

<script>
import InvoiceForm from "@/components/Invoice/InvoiceForm";

export default {
    name: "CreateInvoice",
    components: {InvoiceForm},
}
</script>

<style scoped>

</style>

<template>
    <section id="breadcrumbsBar" class="py-2 bg-white">
        <div class="px-3">
            <!--        <div class="container">-->
            <div class="grid">
                <!--            <div class="flex d-flex justify-content-between">-->
                <b-breadcrumb class="bg-transparent m-0 p-0">
                    <b-breadcrumb-item :active="$route.path==='/home'" to="/home">
                        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                        <span class="ml-3 mr-3"> {{ $t('Home') }}</span>
                    </b-breadcrumb-item>
                    <b-breadcrumb-item class="text-capitalize" v-for="(item,index) in breadcrumbItems"
                                       :active="index===breadcrumbItems.length-1" :key="item">
                        {{ $t(item.replace('-', ' ')) }}
                    </b-breadcrumb-item>
                </b-breadcrumb>
                <div id="stats" class="text-lg-right mt-3 mt-md-0 col-span-2 flexEnd">
                    <span class="">{{ $t('total_amount') }}: <span class="text-danger">{{ totalTotal | comma_separated }} SAR</span></span>
                    <span class="ml-4">{{ $t('paid amount') }}: <span class="text-danger">{{ paid_amount | comma_separated }} SAR</span></span>
                    <span class="ml-4">{{ $t('Balance') }}: <span class="text-danger">{{ (parseFloat(totalUndue)+parseFloat(totalDue)).toFixed(2) | comma_separated }} SAR</span></span>
                    <span class="ml-4"  v-can="'show-admin-controls'"> {{ $t('difference_balance') }} :  <span :class="(parseFloat(totalUndue)+parseFloat(totalDue)).toFixed(2) !=(totalTotal-paid_amount).toFixed(2)? 'text-danger':'text-success'">{{ (totalTotal-paid_amount).toFixed(2)  | comma_separated }}</span> SAR </span>
                    <!-- <span class="ml-4">{{ $t('upcoming_mount') }}: <span class="text-danger">{{ totalUndue | comma_separated }} SAR</span></span>
                    <span class="ml-4">{{ $t('due_amount') }}: <span class="text-danger">{{ totalDue | comma_separated}} SAR</span></span> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// const items = window.location.pathname.slice(1).split('/');
export default {
    name: "Breadcrumbs",
    data() {
        return {
            totalDue: '',
            totalOverdue: '',
            totalTotal: '',
            totalUndue: '',
            paid_amount:'',
            breadcrumbItems: this.$route.path.slice(1).split('/').filter(item => item !== 'home')
        }
    },
    methods: {
        async getValues() {
            const {data} = await this.$axios.get('invoicesTotal');
            this.totalDue = data.due_balance.toFixed(2);
            // this.totalOverdue = data.overdue_balance.toFixed(2);
            this.totalUndue = data.undue_balance.toFixed(2);
            this.paid_amount = data.paid_amount.toFixed(2);
            this.totalTotal = data.total_amount.toFixed(2);
        },

    },
    created() {
        this.getValues();
    },
    watch: {
        $route() {
            this.breadcrumbItems = this.$route.path.slice(1).split('/').filter(item => item !== 'home')
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#breadcrumbsBar {
    position: relative;

    &::after {
        content: '';
        padding: 1px 0;
        width: 100%;
        display: inline-block;
        position: absolute;
        bottom: 0;
        box-shadow: 0px 2px 2px 0px rgba(#000, 0.3);
    }

    .breadcrumb-item {
        a {
            color: #444;
        }
    }

    #stats {
        font-weight: 600;
        font-size: 13px;
    }

    .grid {
        grid-template-columns: 1fr;
        @include lg {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
</style>

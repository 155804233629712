<template>
    <section id="pageHeader" class="border-bottom">
        <div class="">
            <!--        <div class="container">-->
            <div class="grid grid-cols-6 items-center">
                <div class="border-left border-right px-3 py-4 place-items-center d-none d-lg-flex">
                    <!--                    <b-avatar size="50px"></b-avatar>-->
                    <!--                    <img id="userImage" :src="../../assets/images/avatar.png" alt="">-->

                    <img v-if="getUser().image" class="userImage" :src="getUser().image.path" alt="">
                    <img v-else class="userImage" src="@/assets/images/placeholder-avatar.png" alt="">
                    <div id="userName" class="ml-2">
<!--                        <p class="mb-0"></p>-->
                        <p class="m-1">{{ $t('Welcome') }} {{ getUser().first_name }} {{ getUser().last_name }}</p>
                        <p v-if="getUser().customer_id_number" class="mb-0">CRR {{ getUser().customer_id_number }}</p>
                    </div>
                </div>
                <div class="d-flex justify-content-between col-span-4 px-md-4">
                    <b-icon v-b-toggle.slideSidebar class="border self-center d-lg-none" font-scale="1.8"
                            icon="list"></b-icon>
                    <h2 id="heading" class="text-uppercase text-dark">{{
                            $route.name != null ? $t($route.name.match(/[A-Z][a-z]+/g).join(' ')) : $t('Dashboard')
                        }}</h2>
                </div>
                <div class="text-right px-3 flexEnd">
                    <b-button variant="info" @click="goBack" class="px-3">
                        <b-icon icon=" arrow-left-short" scale="1.5" aria-hidden="true"></b-icon>
                        {{ $t('Back') }}
                    </b-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "PageHeader",
    data() {
        return {
            user: '',
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getUser() {
            let user = JSON.parse(localStorage.getItem('user'));
            return user
        },
        setAltAvatar(event) {
            event.target.src = "@/assets/images/visa.png"
            // event.target.src = "https://place-hold.it/600x600&text=color&fontsize=38"
        },

        created() {


        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#pageHeader {
    background-color: #f1f1f1;

    #heading {
        font-weight: 700;
        font-size: 20px;
        align-self: center;
        margin-bottom: 0;
    }

    .userImage {
        width: 60px;
    }

    #userName {
        font-weight: 600;
    }

    .grid {
        @media screen and (max-width: 991px) {
            grid-template-columns: 4fr;
            padding: 10px 0;
        }
    }

}
</style>

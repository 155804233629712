<template>
    <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
    </div>
</template>

<script>
export default {
    name: "tableLoader"
}
</script>

<style scoped>

</style>

<template>
    <div class="d-flex items-center vh-100">
        <div class="mx-auto w-50">
            <div class="text-center mb-4">
                <img class="w-50" src="../assets/images/logo.png" alt="">
            </div>
            <b-card>
                <div class="grid text-5xl font-weight-light text-center text-muted">{{$t('two_factor_authentication')}}</div>
                <form @submit="verification" action="" method="post" class="mt-4 w-75 mx-auto">
                    <validation-observer ref="loginForm">
                        <b-form-group label-cols="3" :label="$t('enter_code')">
                            <text-validator v-model="tfa_code" name="two_factor_code" rules="required"
                                            type="password"></text-validator>
                        </b-form-group>
 
                        <b-form-group class="mt-4 text-center px-3">
                            <button  class="primary-btn px-3 m-3 green ">{{$t('submit')}}</button>
                            <button @click="goBack" class="primary-btn px-3 m-3 ">
                                <b-icon icon=" arrow-left-short" scale="1.5" aria-hidden="true"></b-icon>
                                {{ $t('Back') }}
                        </button>
                        </b-form-group>
                    </validation-observer>
                </form>

            </b-card>
        </div>
    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert} from "@/components/Theme/Alerts";

export default {
    name: "TwoFactorAuthentication",
    components: {TextValidator, ValidationObserver},
    data() {
        return {

            tfa_code: ''
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async verification(e) {
            e.preventDefault()
            this.$isLoading(true);
            // const valid = await this.$refs.loginForm.validate();
            // if (valid) {
            try {
                const code = {
                    two_factor_code: this.tfa_code,
                    user_email: localStorage.getItem('user_email'),
                }
                const {data} = await this.$axios.post('two_factor_authentication', code)
                this.$axios.defaults.headers.Authorization = `Bearer ${data.user.token}`
                localStorage.setItem('user', JSON.stringify(data.user));
                this.$isLoading(false);
                this.$router.push('home')
            } catch (e) {
                this.$isLoading(false);
                errorAlert(this.$t('Invalid Credentials'), this.$t('Invalid code'))
            }
            this.$isLoading(false);
            // }
        }
    }
}
</script>

<style scoped>

</style>

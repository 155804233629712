<template>
    <div class="d-flex items-center vh-100">
        <div id="loginFrame" class="mx-auto">
            <div class="text-center mb-4">
<!--                <img class="w-50" src="../assets/images/logo.png" alt="">-->
            </div>
            <b-card >
                <div class=" grid text-5xl font-weight-light text-muted">Forgot Password</div>
                <form @submit="forgotPassword" action="" method="post" class="mt-4 w-75 mx-auto">
                    <validation-observer ref="loginForm">
                        <b-form-group  label-cols="12" label="Email Address">
                            <text-validator v-model="email" name="email" rules="required|email"
                                            type="email"></text-validator>
                        </b-form-group>

                        <b-form-group class="mt-4" label-cols="12">
                            <button class="primary-btn green">Submit</button>
                            <div class="mt-2">
<!--                                <b-link to="forgot-password">Forgot your password?</b-link>-->
                            </div>
                        </b-form-group>
                    </validation-observer>
                </form>
            </b-card>
        </div>
    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert, successAlert} from "@/components/Theme/Alerts";

export default {
    name: "Login",
    components: {TextValidator, ValidationObserver},
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        async forgotPassword(e) {
            e.preventDefault()
            // const valid = await this.$refs.loginForm.validate();
            // if (valid) {
                try {
                    const forgotData = {
                        email: this.email,
                        token: '12345678',
                    }
                    const {data} = await this.$axios.post('forgotPassword', forgotData)
                    await successAlert('Email', 'Sent')
                    console.log(data)
                } catch (e) {
                    errorAlert('Error', 'Something went wrong')
                }
            // }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#loginFrame{
    width: 90%;
    @include lg{
        width: 70%;
    }
    @include xl{
        width: 50%;
    }
}


</style>



import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {router} from "@/routes";
import './assets/sass/app.scss'
import './assets/sass/arabic.css'
import axiosInstance from "@/axios";
import {store} from "@/store";
import excel from 'vue-excel-export'
import {i18n} from "./plugins/i18n";
import loading from 'vuejs-loading-screen'
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(excel)
Vue.use(loading,{
    bg: 'rgba(98, 98, 98, 0.68)',
    slot: `<div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>`
  })

Vue.config.productionTip = false
Vue.prototype.$axios = axiosInstance
Vue.filter("comma_separated", str => str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))

Vue.directive('can', {
    inserted(el, binding) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            if (!user.permissions.includes(binding.value)) {
                const parent = el.parentNode;
                const comment = document.createComment('');
                el.appendChild(comment);
                parent.replaceChild(comment, el);
            }
        }
    }
})

new Vue({
    i18n,
    store,
    render: h => h(App),
    router
}).$mount('#app')
// new Vue({
//     render: h => h(App),
//     router,
//     store
// }).$mount('#app')

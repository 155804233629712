<template>
    <section>
        <b-card class="shadow-sm">
            <div class="  py-3">
                <div class="d-flex justify-content-between w-25 text-center" >
                    <p class="font-weight-bold" >{{$t('Contract#')}}: </p>
                    <p> {{ this.contract.number }} </p>
                </div>
                <div class="d-flex justify-content-between w-35 text-center" >
                    <p class="font-weight-bold">{{$t('Contract Name')}}: </p>
                    <p> {{ this.contract.name}} </p>
                </div>

                <div v-if="contract.user" class="d-flex justify-content-between   w-25 text-center" >
                    <p class="font-weight-bold">{{$t('Payer No')}}: </p>
                    <p v-if="contract.user.payer_number != 'null'"> {{ this.contract.user.payer_number}} </p>
                </div>
                <div v-if="contract.user" class="d-flex justify-content-between  w-25 text-center" >
                    <p class="font-weight-bold">{{$t('Customer No')}}: </p>
                    <p v-if="contract.user.customer_number != 'null'"> {{ this.contract.user.customer_number}} </p>
                </div>
                <div class="d-flex justify-content-between w-25 text-center" >
                    <p class="font-weight-bold">{{$t('Status')}}: </p>
                    <p> {{ this.contract.status }} </p>
                </div>
                <div class="d-flex justify-content-between w-25 text-center" >
                    <p class="font-weight-bold">{{$t('Start Date')}}: </p>
                    <p> {{ this.contract.start_date | moment }} </p>
                </div>
                <div class="d-flex justify-content-between w-25 text-center" >
                    <p class="font-weight-bold">{{$t('End Date')}}: </p>
                    <p> {{ this.contract.end_date | moment }} </p>
                </div>

                <h2 class="pt-3 arabicDir">{{$t('Installments')}} </h2>
                <b-card class="shadow-sm mt-2" body-class="p-0">
                    <b-table head-variant="dark" small
                             class="text-center"
                             :items="items"
                             show-empty bordered
                             :fields="fields"
                    >
                        <template #cell(created_at)="data">
                            {{ data.item.created_at | moment }}
                        </template>
                        <template #cell(due_date)="data">
                            {{ data.item.due_date | moment }}
                        </template>
                        <template #cell(status)="data">

                            {{ data.item.status =='undue'?'upcoming':data.item.status  }}
                        </template>
                        <template #cell(total_amount)="data">
                            {{ data.item.total_amount | comma_separated }}
                        </template>
                        <template #cell(paid_amount)="data">
                            {{ data.item.paid_amount | comma_separated }}
                        </template>

                    </b-table>
                </b-card>
            </div>

        </b-card>
    </section>
</template>

<script>
import moment from "moment";
export default {
    name: "ContractDetails",
    data() {
        return {

            items: [],
            contract: [],
        }
    },
    computed:{
        fields() {
            return [
                {
                    label: this.$t('Sr#'),
                    key: 'erp_inst_primary_id',
                    formatter: (value) => {
                        return value.split('-')[1]
                    }
                },
                {
                    label: this.$t('Installment Number'),
                    key: 'ref_number'
                },
                {
                    label: this.$t('Invoice Date'),
                    key: 'created_at'
                },
                {
                    label: this.$t('Due date'),
                    key: 'due_date'
                },
                {
                    label: this.$t('Total'),
                    key: 'total_amount'
                },
                {
                    label: this.$t('Paid Amt'),
                    key: 'paid_amount'
                },
                {
                    label: this.$t("Status"),
                    key: "status",
                },
            ]
        }
    },
    methods: {
        async getContract() {
            const {data} = await this.$axios.get('contract/' + this.$route.params.id);
            this.contract = data[0];
            this.items = data[0].installments;
            // console.log(this.contract.installments);

        },
    },
    created() {
        this.getContract();
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },

}
</script>

<style scoped>
.w-35{
    width: 35% !important;
}
</style>

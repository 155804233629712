<template>
    <div v-if="checkIfPermission('partial payments')">
        <b-form-input
            @change="(value) =>  checkedInvoiceHandler(value,data.item.id,data.item.balance, name)"
            v-if="hasChecked(data.item.id, name)"
            :value="getCheckInvoice(data.item.id, name).balance  | comma_separated" disabled
        ></b-form-input>
        <b-form-input :value="data.item.balance  | comma_separated" v-else-if="data.item.status!='paid'"
                      disabled></b-form-input>
    </div>
     <div v-else>
         <b-form-input :value="data.item.balance | comma_separated" v-if="data.item.status!='paid'" disabled></b-form-input>
     </div>
</template>

<script>
import isEmpty from "lodash.isempty";
import {cloneDeep} from "lodash";

export default {
    name: "InvoicePayAmount",
    props: [
        'data', 'name', 'checkedInvoices'
    ],
    methods: {
        checkIfPermission(value){
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                if(!user.permissions.includes(value)) {
                    return false;
                }else {
                    return true;
                }
            } else{
                return false;
            }
        },
        checkedInvoiceHandler(val, id, balance, arr) {
            if (!isEmpty(this.checkedInvoices[arr])) {
                const checkedInvoices = cloneDeep(this.checkedInvoices);
                // console.log(checkedInvoices);
                const item = checkedInvoices[arr].find(inv => inv.id === id);
                item.balance = val;

                // if (val > 0 && val <= balance) {
                //     item.balance = val;
                // } else {
                //     item.balance = 0;
                // }
                this.$emit('getBackCheckedInvoices', checkedInvoices)
            }
        },
        hasChecked(id, arr) {
            if (!isEmpty(this.checkedInvoices[arr])) {
                return this.checkedInvoices[arr].some(inv => inv.id === id);
            } else {
                return false;
            }
        },
        getCheckInvoice(id, arr) {
            if (!isEmpty(this.checkedInvoices[arr])) {
                return this.checkedInvoices[arr].find(inv => inv.id === id);
            }
        },

    }
}
</script>

<style scoped>

</style>

<template>
    <b-card body-class="p-0" class="shadow-sm">
        <div id="stats" class="d-flex">
            <div v-for="(invoiceTotal,invoiceType) in invoices" :key="invoiceType" class="py-1">
                <div class="d-flex align-items-center justify-content-center border-right arabicDirInit">
                    <div class="text-6xl">
                        <b-icon :class="[`bg-${icons[invoiceType].color}`]" class="rounded-circle p-2" variant="white"
                                :icon="icons[invoiceType].icon"></b-icon>
                    </div>
                    <div class="ml-4 text-center">
                        <h4 class="text-dark mb-2">{{ $t(statsHeading[invoiceType]) }}</h4>
                        <div :class="[`text-${icons[invoiceType].color}`]"
                             class="text-center text-3xl font-weight-normal">
                            {{ invoiceTotal | comma_separated }}
                            SAR
                        </div>
<!--                        <div class="text-muted text-center mt-1">Overdue by 45 days</div>-->
                    </div>
                </div>
                <div v-if="allowFilter" class="text-right">
                    <b-checkbox :checked="type.includes(invoiceType.split('_')[0])" class="invoice-filter-checkbox"
                                :name="invoiceType"
                                size="lg"
                                @change="(checked) => $emit('change',checked,invoiceType.split('_')[0])"
                    ></b-checkbox>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
export default {
    name: "Stats",
    data() {
        return {
            statsHeading: {
                due_balance: 'Due Invoices',
                /*overdue_balance: 'Overdue Invoices',*/
                undue_balance: 'Upcoming Invoices',
                paid_amount: 'Paid Invoices'
            },
            icons: {
                due_balance: {
                    color: 'warning',
                    icon: 'calendar2-date'
                },
                /*overdue_balance: {
                    color: 'danger',
                    icon: 'exclamation'
                },*/
                undue_balance: {
                    color: 'secondary',
                    icon: 'arrow-clockwise'
                },
                paid_amount: {
                    color: 'success',
                    icon: 'calendar2-check'
                },
            }
        }
    },
    props: {
        invoicesTotal: {
            type: Object
        },
        hidden: {
            type: Array,
            default: () => []
        },
        allowFilter: {
            type: Boolean,
            default: false
        },
        type: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        invoices: function () {
            const invoices = {};
            Object.keys(this.invoicesTotal).forEach(item => {
                if (!this.hidden.includes(item)) {
                    invoices[item] = this.invoicesTotal[item].toFixed(2)
                }
            })
            return invoices;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/sass/_mixins.scss";

#stats {
    //grid-template-columns: repeat(1, 1fr);
    //place-items: center;

    & > div {
        width: 100%;
        border-top: 2px solid;

        &:first-child {
            border-top-color: $warning;
        }

        &:nth-child(2) {
            border-top-color: $secondary;
        }

        &:nth-child(3) {
            border-top-color: $success;
        }

        &:nth-child(4) {
            border-top-color: $success;
        }
        &:last-of-type > div{
            border-right: none !important;
        }
    }

    //@include lg {
    //    grid-template-columns: repeat(3, 1fr);
    //    border-bottom: none;
    //    & > div {
    //        &:not(:first-child) {
    //            //border-left: 1px solid #eee;
    //        }
    //
    //        border-bottom-color: transparent !important;
    //    }
    //}
}
</style>

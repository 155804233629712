<template>
    <div class="border border-top-0 mb-3 sticky-top" id="sideBar">
        <div :class="isActive('dashboard')" class="header border-bottom d-flex align-items-center px-2 py-3">
            <b-icon class="ml-2" icon="clipboard-plus" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="ml-3 font-weight-bold" to="/home">{{ $t('Home') }}</b-link>
        </div>
        <div>
            <div :class="isActive('contracts/all')" class="header border-bottom d-flex px-2 py-3 align-items-center"
                 v-b-toggle.collapse-2
                 @click="toggleCollapse(2)">
                <div class="d-flex align-items-center flex-grow-1 ml-2">
                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
                    <b-link class="font-weight-bold ml-3" :to="{name:'Contracts',params:{type: 'all'}}">
                        {{ $t('contracts') }}
                    </b-link>
                </div>
                <b-icon v-if="openedAccordions.includes(2) " icon="caret-up-fill" scale="0.8"
                        aria-hidden="true"></b-icon>
                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>
            </div>

            <!-- Element to collapse -->
            <b-collapse :visible="isActiveMenu('contracts/all')" accordion="my-accordion" id="collapse-2">
                <div class="sidebar-item" :class="isActive('category-invoices')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'CategoryInvoices'}">{{ $t('Contract Details') }}</b-link>
                </div>
                <div class="sidebar-item" :class="isActive('contracts/open')">
                    <b-icon icon=" file-earmark-richtext" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'Contracts',params:{type: 'open'}}">{{ $t('Open Contracts') }}</b-link>
                </div>
<!--                <div class="sidebar-item" :class="isActive('contracts/close')">
                    <b-icon icon=" file-earmark-ppt-fill" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'Contracts',params:{type: 'close'}}">Closed Contracts</b-link>
                </div>-->
<!--                <div class="sidebar-item" :class="isActive('payment-remaining')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    &lt;!&ndash;                <p class="mb-0 ml-2">Payments Remaining</p>&ndash;&gt;
                    <b-link to="/payment-remaining">Payments Remaining</b-link>
                </div>-->
            </b-collapse>
        </div>
        <div>
            <div :class="isActive('invoices')" class="header border-bottom d-flex px-2 py-3 place-items-center"
                 v-b-toggle.collapse-3
                 @click="toggleCollapse(3)">
                <div class="d-flex align-items-center flex-grow-1 ml-2">
                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
                    <b-link class="font-weight-bold ml-3" :to="{name:'Invoices'}">{{ $t('Invoices') }}</b-link>
                </div>
                <b-icon v-if="openedAccordions.includes(3)" icon="caret-up-fill" scale="0.8"
                        aria-hidden="true"></b-icon>
                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>
            </div>
            <b-collapse :visible="isActiveMenu('invoices')"  accordion="my-accordion" id="collapse-3">
                <div class="sidebar-item" :class="isActive('invoices/due')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name:'Invoices',params:{type: 'due'}}">{{ $t('Due Invoices') }}</b-link>
                </div>
<!--                <div class="sidebar-item" :class="isActive('invoices/overdue')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'Invoices',params:{type: 'overdue'}}">Overdue Invoices</b-link>
                </div>-->
                <div class="sidebar-item" :class="isActive('invoices/undue')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'Invoices',params:{type: 'undue'}}">{{ $t('Upcoming Invoices') }}</b-link>
                </div>
                <div class="sidebar-item" :class="isActive('invoices/paid')">
                    <b-icon icon=" file-earmark-text" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link :to="{name: 'Invoices',params:{type: 'paid'}}">{{ $t('Paid Invoices') }}</b-link>
                </div>
            </b-collapse>
        </div>
        <div v-can="'view departments'" :class="isActive('department-list')"
             class="header border-bottom d-flex items-center px-2 py-3">
            <b-icon class="ml-2" icon="clipboard-plus" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="ml-3 font-weight-bold" to="/department-list">{{ $t('Departments') }}</b-link>
        </div>
        <div :class="isActive('payments')" class="header border-bottom d-flex px-2 py-3 align-items-center">
            <b-icon class="ml-2" icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="font-weight-bold ml-3" to="/payments">{{ $t('Payments') }}</b-link>
        </div>
        <div :class="isActive('sadad-transactions')" class="header border-bottom d-flex px-2 py-3 align-items-center">
            <b-icon class="ml-2" icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="font-weight-bold ml-3" to="/sadad-transactions">{{$t('SADAD Transactions') }}</b-link>
        </div>
        <div v-can="'show-admin-controls'" :class="isActive('sadad-pending-invoices')" class="header border-bottom d-flex px-2 py-3 align-items-center">
            <b-icon class="ml-2" icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="font-weight-bold ml-3" to="/sadad-pending-invoices">{{$t('Sadad Pending Invoices') }}</b-link>
        </div>
        <div v-can="'show-admin-controls'" :class="isActive('sadad-paid-invoices')" class="header border-bottom d-flex px-2 py-3 align-items-center">
            <b-icon class="ml-2" icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
            <b-link class="font-weight-bold ml-3" to="/sadad-paid-invoices">{{$t('Sadad Paid Invoices') }}</b-link>
        </div>
        <div>
            <div :class="isActive('notifications/profile')" class="header border-bottom d-flex px-2 py-3 align-items-center" v-b-toggle.collapse-4
                 @click="toggleCollapse(4)">
                <div class="d-flex align-items-center flex-grow-1 ml-2">
                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
                    <div class="font-weight-bold ml-3">{{$t('Manage Profile') }}</div>
                </div>
                <b-icon v-if="openedAccordions.includes(4)" icon="caret-up-fill" scale="0.8"
                        aria-hidden="true"></b-icon>
                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>
            </div>
            <!-- Element to collapse -->
            <b-collapse  :visible="isActiveMenu('notifications/profile')" accordion="my-accordion"  id="collapse-4">

<!--                <div class="sidebar-item justify-content-between" :class="isActive('category-invoices')">-->
<!--                    <div class="d-flex  align-items-center">-->
<!--                        <b-icon icon="envelope" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                        <p class="mb-0 ml-2">Messages Center</p>-->
<!--                    </div>-->
<!--                    <b-badge class="mr-2" variant="warning">2</b-badge>-->
<!--                </div>-->

                <!-- <div class="sidebar-item justify-content-between" :class="isActive('notifications')">
                    <div class="d-flex align-items-center">
                        <b-icon icon="bell" scale="1.25" aria-hidden="true"></b-icon>
                        <b-link to="/notifications">{{ $t('Alerts & Notifications') }}</b-link>
                    </div> -->
<!--                    <b-badge class="mr-2" variant="warning">7</b-badge>-->
                <!-- </div> -->
                <div class="sidebar-item" :class="isActive('change-password')">
                    <b-icon icon="person" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/change-password">{{ $t('Change Password') }}</b-link>
                </div>
                <div class="sidebar-item" :class="isActive('profile')">
                    <b-icon icon="person" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/profile">{{ $t('Update Profile') }}</b-link>
                </div>
            </b-collapse>
        </div>
        <div>
            <div :class="isActive('support')"  class="header border-bottom d-flex px-2 py-3 place-items-center" v-b-toggle.collapse-5
                 @click="toggleCollapse(5)">
                <div class="d-flex align-items-center flex-grow-1 ml-2">
                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
                    <div class="ml-3 font-weight-bold text-dark">{{ $t('Help & Tools') }}</div>
                </div>
                <b-icon v-if="openedAccordions.includes(5)" icon="caret-up-fill" scale="0.8"
                        aria-hidden="true"></b-icon>
                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>
            </div>
            <b-collapse :visible="isActiveMenu('support')" accordion="my-accordion" id="collapse-5">
                <div class="sidebar-item" :class="isActive('support')">
                    <b-icon icon=" envelope" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/support">{{ $t('Support Request') }}</b-link>
                </div>

<!--                <div class="sidebar-item" :class="isActive('faqs')">-->
<!--                    <b-icon icon="question-circle" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/faqs">FAQS</b-link>-->
<!--                </div>-->
<!--                <div class="sidebar-item" :class="isActive('user-guide')">-->
<!--                    <b-icon icon="chat-right-text" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/user-guide">User Guides</b-link>-->
<!--                </div>-->
            </b-collapse>
        </div>
        <div v-can="'show-admin-controls'">

            <!--visible to admin only-->
            <div  :class="isActive('admin')" class="header border-bottom d-flex px-2 py-3 align-items-center" v-b-toggle.collapse-6
                 @click="toggleCollapse(6)">
                <div class="d-flex align-items-center flex-grow-1 ml-2">
                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>
                    <div class="font-weight-bold ml-3">{{ $t('Admin') }}</div>
                </div>
                <b-icon v-if="openedAccordions.includes(6)" icon="caret-up-fill" scale="0.8"
                        aria-hidden="true"></b-icon>
                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>
            </div>

            <b-collapse :visible="isActiveMenu('admin')" accordion="my-accordion" id="collapse-6">
                <div v-can="'view users'" class="sidebar-item" :class="isActive('users')">
                    <b-icon icon="person" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/users">{{ $t('Users') }}</b-link>
                </div>
                <div v-can="'view roles'" class="sidebar-item" :class="isActive('roles')">
                    <b-icon icon="people" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/roles">{{ $t('Roles') }}</b-link>
                </div>
                <div v-can="'view permissions'" class="sidebar-item" :class="isActive('permissions')">
                    <b-icon icon="key" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/permissions">{{ $t('Permissions') }}</b-link>
                </div>
                <div class="sidebar-item" :class="isActive('useractivity')">
                    <b-icon icon="question-circle" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/useractivity">{{$t('User Activity')}}</b-link>
                </div>
                <div class="sidebar-item" :class="isActive('revisions')">
                    <b-icon icon="question-circle" scale="1.25" aria-hidden="true"></b-icon>
                    <b-link to="/revisions">{{$t('Revisions')}}</b-link>
                </div>
            </b-collapse>
        </div>
        <div v-can="'show-audit-controls'">
            <!--visible to admin only-->
<!--            <div class="header border-bottom d-flex px-2 py-3 align-items-center" v-b-toggle.collapse-7-->
<!--                 @click="toggleCollapse(7)">-->
<!--                <div class="d-flex align-items-center flex-grow-1 ml-2">-->
<!--                    <b-icon icon="file-earmark-text" scale="1.75" aria-hidden="true"></b-icon>-->
<!--                    <div class="font-weight-bold ml-3">Audit Trail</div>-->
<!--                </div>-->
<!--                <b-icon v-if="openedAccordions.includes(7)" icon="caret-up-fill" scale="0.8"-->
<!--                        aria-hidden="true"></b-icon>-->
<!--                <b-icon v-else icon="caret-down-fill" scale="0.8" aria-hidden="true"></b-icon>-->
<!--            </div>-->

<!--            <b-collapse id="collapse-7">-->
<!--                <div v-can="'view users'" class="sidebar-item" :class="isActive('users')">-->
<!--                    <b-icon icon="person" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/users">Who</b-link>-->
<!--                </div>-->
<!--                <div v-can="'view roles'" class="sidebar-item" :class="isActive('roles')">-->
<!--                    <b-icon icon="people" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/roles">When</b-link>-->
<!--                </div>-->
<!--                <div v-can="'view permissions'" class="sidebar-item" :class="isActive('permissions')">-->
<!--                    <b-icon icon="key" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/permissions">Where</b-link>-->
<!--                </div>-->
<!--                <div v-can="'view permissions'" class="sidebar-item" :class="isActive('permissions')">-->
<!--                    <b-icon icon="key" scale="1.25" aria-hidden="true"></b-icon>-->
<!--                    <b-link to="/permissions">What</b-link>-->
<!--                </div>-->
<!--            </b-collapse>-->
        </div>
    </div>

</template>

<script>
export default {
    name: "Sidebar",
    data() {
        return {
            openedAccordions: [],
        }
    },
    methods: {
        toggleCollapse(id) {
            
            let openedAccordions = [];
            openedAccordions = this.openedAccordions;
            if (openedAccordions.includes(id)) {
                openedAccordions.remove
                openedAccordions = openedAccordions.filter(item => item !== id);
            } else {
                this.openedAccordions.push(id);
            }
            this.openedAccordions = openedAccordions;
        },
        isActive(path) {
            if((this.$route.path.slice(1) == 'category-invoices' &&  path == 'contracts/all') ||  (this.$route.path.slice(1) == 'contracts/open' &&  path == 'contracts/all') || ((this.$route.path.slice(1).includes('contract-details') &&  path == 'contracts/all') ||  (this.$route.path.slice(1).includes('contract-details') &&  path == 'category-invoices'))){
                // this.toggleCollapse(2)
                return {'bg-green': true}
            }

            if(
                (this.$route.path.slice(1) == 'invoices/due' &&  path == 'invoices') ||  (this.$route.path.slice(1) == 'invoices/undue' &&  path == 'invoices')
                || (this.$route.path.slice(1) == 'invoices/paid' &&  path == 'invoices')
            ){
                // this.toggleCollapse(3)
                return {'bg-green': true}
            }
            if(path =='notifications/profile' &&  (this.$route.path.slice(1) == 'notifications' ||  this.$route.path.slice(1) == 'profile' ||  this.$route.path.slice(1) == 'change-password')){
                // this.toggleCollapse(4)
                return {'bg-green': true}
            }
            if(path =='admin' && (this.$route.path.slice(1) == 'users' ||  this.$route.path.slice(1) == 'roles' || this.$route.path.slice(1) == 'permissions' ||  this.$route.path.slice(1) == 'useractivity' ||  this.$route.path.slice(1) == 'revisions' )){
                // this.toggleCollapse(6)
                return {'bg-green': true}
            }


            return {'bg-green': this.$route.path.slice(1) === path}
        },
        isActiveMenu(path) {
            if((this.$route.path.slice(1) == 'category-invoices' &&  path == 'contracts/all') ||  (this.$route.path.slice(1) == 'contracts/open' &&  path == 'contracts/all') || ((this.$route.path.slice(1).includes('contract-details') &&  path == 'contracts/all') ||  (this.$route.path.slice(1).includes('contract-details') &&  path == 'category-invoices'))){
                return  true
            }

            if(
                (this.$route.path.slice(1) == 'invoices/due' &&  path == 'invoices') ||  (this.$route.path.slice(1) == 'invoices/undue' &&  path == 'invoices')
                || (this.$route.path.slice(1) == 'invoices/paid' &&  path == 'invoices')
            ){
                return  true
            }
            if(path =='notifications/profile' &&  (this.$route.path.slice(1) == 'notifications' ||  this.$route.path.slice(1) == 'profile' ||  this.$route.path.slice(1) == 'change-password')){
                return  true
            }
            if(path =='admin' && (this.$route.path.slice(1) == 'users' ||  this.$route.path.slice(1) == 'roles' || this.$route.path.slice(1) == 'permissions' ||  this.$route.path.slice(1) == 'useractivity' ||  this.$route.path.slice(1) == 'revisions' )){
                return  true
            }
            return false;
        }
    },
    mounted() {

    },
}
</script>

<style lang="scss">

@import "@/assets/sass/_variables";

#sideBar {
    background-color: #f1f1f1;
    max-height: calc(100vh - 255px);
    top: 155px;
    overflow-y: auto;

    .sidebar-item {
        @extend .border-bottom;
        @extend .d-flex;
        @extend .pl-5;
        @extend .py-2 ;
        @extend .align-items-center;
        background: white;

        a {
            @extend .ml-2;
            color: $dark;
        }
    }

    .header {
        a {
            color: $dark;
        }

        &.bg-green {
            a {
                color: white;
            }
        }
    }

    & .bg-green {
        background: $green-color;
        color: white !important;

        & > a {
            color: white !important;

            &:hover {
                color: white;
            }
        }
    }
}


#item {
    background-color: rgb(255, 255, 255);
    width: 100%;
}

.li a {
    text-decoration: none;
    color: black;
}

:focus {
    outline: 0;
}

</style>

<template>
    <div>
        <h3 class="text-3xl">{{ $t('Permissions') }}</h3>
        <form @submit.prevent="save" action="">
            <validation-observer ref="permissionForm">
                <b-form-group :label="$t('Name')">
                    <text-validator v-model="permission.name" name="name" rules="required"></text-validator>
                </b-form-group>
                <div class='text-right'>
                    <button class="primary-btn green">{{ $t(('Save')) }}</button>
                </div>
            </validation-observer>
        </form>

    </div>
</template>

<script>
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert, successAlert, validationAlert} from "@/components/Theme/Alerts";


export default {
    name: "PermissionForm",
    components: {TextValidator, ValidationObserver},
    data() {
        return {
            permission: {
                name: ''
            }
        }
    },
    props: ['formType', 'permissionProp'],
    created() {
        if (this.formType === 'edit') {
            this.permission = this.permissionProp
        }
    },
    methods: {
        async save() {
            const valid = await this.$refs.permissionForm.validate()
            if (valid) {
                try {
                    let url, method;
                    if (this.formType === 'add') {
                        url = 'permissions';
                        method = 'post'
                    } else {
                        url = `permissions/${this.permission.id}`
                        method = 'put'
                    }
                    await this.$axios[method](url, this.permission)
                    await successAlert('Permission', 'saved');
                    this.$emit('close');
                } catch (e) {
                    if (e.response.status === 422) {
                        await validationAlert(e.response.data.errors)
                    } else {
                        await errorAlert('Permission', 'creating');
                    }
                }
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <h3 class="text-3xl">Payment History</h3>
        <b-table class="mt-3 text-center" head-variant="dark"  :items="payments" bordered small :fields="fields"></b-table>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "PaymentHistory",
    props: ['payments'],
    data() {
        return {
            fields: [
                {
                    label: 'Date',
                    key: 'created_at',
                    formatter: (value) => {
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {
                    label: 'Method',
                    key: 'payment_method.name'
                },
                {
                    label: 'Amount',
                    key: 'pivot.amount'
                }
            ],
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="accordion checkoutPanel px-2" role="tablist">
        <h2 class="my-3 text-center font-weight-bold">{{$t('Checkout Receipt')}}</h2>
        <section v-for="(invoiceArr, name) in invoices" :key="name" class="mb-1">
            <h4 class="heading p-1 mb-0 text-capitalize arabicDir">{{ $t(name) }}</h4>
            <b-table
                responsive
                head-variant="dark"
                custom-foot
                foot-variant="dark"
                small
                :items="invoiceArr"
                tbody-tr-class="bg-white"
                class="shadow-sm text-sm"
                show-empty
                bordered
                :fields="checkoutFields"
            >
                <template  #cell(pay)="data">
                    <div v-if="arrayName" class="text-center">
                        <b-icon-dash class="alert-danger" @click="checkoutHandlerChild(false, data.item, arrayName)" > </b-icon-dash>
                    </div>
                </template>
                <template #cell(number)="data">
                    <div>{{ data.item.number }}</div>
                </template>
                <template #cell(installment.contract.number)="data">
                    <div v-if="data.item.installment">
                        {{ data.item.installment.contract.number }}
                    </div>
                </template>
                <template #cell(installment.created_at)="data">
                    {{ data.item.installment.created_at | moment }}
                </template>
                <template  v-if="arrayName"  #cell(balance)="data">
                    <InvoicePayAmount
                        :data="data"
                        name="invoices"
                        :checkedInvoices="invoices"
                        @getBackCheckedInvoices="updateInvoicesFunc"
                    ></InvoicePayAmount>
                </template>

                <template #custom-foot>
                    <b-tr>
                        <b-td class="text-right font-weight-semi-bold" colspan="4"
                        >{{$t('Total Amount')}}
                        </b-td
                        >
                        <b-td>{{ subtotal(invoiceArr) | comma_separated }}</b-td>
                    </b-tr>
                </template>
            </b-table>
        </section>
        <div class="bg-dark d-flex justify-content-around text-light py-1">
            <div>{{$t('Total Amount')}}:</div>
            <div>{{ total | comma_separated }} SAR</div>
        </div>

        <div class="m-4">
            <b-form-group :label="$t('Payment Method')" v-slot="{ ariaDescribedby }">
                <div class="d-flex justify-content-around">
                    <div v-for="method in paymentMethods" :key="method.id">
                        <b-form-radio
                            @change="paymentErrorHandler"
                            v-model="payment_method"
                            :aria-describedby="ariaDescribedby"
                            name="paymentMethod"
                            :value="method.id"
                            required
                        >
                            <img
                                @error="setAltImage"
                                :src="method.image.path"
                                width="70px"
                                height="40px"
                                alt=""
                            />
                        </b-form-radio>
                    </div>
                </div>
                <div v-if="paymentError">
                    <p class="text-danger">{{$t('Payment method is required')}}</p>
                </div>
            </b-form-group>
            <div class="text-center checkoutActions">
                <button @click="pay" class="btn btn-primary text-center">
                    {{$t('Pay Now')}}
                </button>
                <b-button @click="$emit('cancelPayment')" class="" variant="danger"
                >{{ $t('Cancel') }}
                </b-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {confirmOptionAlert, errorAlert, successAlert,} from "@/components/Theme/Alerts";
import {roles} from "@/constants";
import InvoicePayAmount from "@/components/Invoice/InvoicePayAmount";

export default {
    name: "InvoiceCheckoutList",
    components: {
        InvoicePayAmount,
    },
    computed:{
        checkoutFields() {
            return [
                {
                    label: "#",
                    key: "pay",
                    visible: true,
                    thStyle: {
                        width: "30px",
                    },
                },
                {
                    label: this.$t("Invoices#"),
                    key: "number",
                },
                {
                    label: this.$t("Contract#"),
                    key: "installment.contract.number",
                },
                {
                    label: this.$t("Invoice Date"),
                    key: "installment.created_at",
                },
                {
                    label: this.$t("Amount"),
                    key: "balance",
                },
            ]
        }
    },
    data() {
        return {

            total: 0,
            payment_method: "",
            constRoles: roles,
            invoicesToPay: [],
            paymentMethods: [],
            paymentError: false,
            invoiceSadadExists: false,
        };
    },
    // props: ['invoices', 'paymentMethods'],
    props: ["invoices","arrayName"],
    created() {
        this.calculateTotal();
        this.getPaymentMethods();
    },
    methods: {
        updateInvoicesFunc(invoices) {
            // console.log('updateInvoices(invoices)',invoices,'=============setp2==================>');
            this.$emit('getBackCheckedInvoicesFunc', invoices)
        },
        checkoutHandlerChild(checked, item, categoryName) {
            this.$emit('getCheckoutHandler', checked, item, categoryName)
        },
        getUser() {
            let user = JSON.parse(localStorage.getItem("user"));
            return user;
        },
        subtotal(arr) {
            let subtotal = arr.reduce(function (a, c) {
                return a + Number(c.balance || 0);
            }, 0);
            return subtotal;
        },
        calculateTotal() {
            let total = 0;
            this.invoicesToPay = [];
            const invoicesToPay = [];
            for (const invoiceArr in this.invoices) {
                this.invoices[invoiceArr].forEach((item) => {
                    total += Number(item.balance);
                    invoicesToPay.push(item.id);
                });
            }
            this.invoicesToPay = invoicesToPay;
            this.total = total;
        },

        async pay() {
            if (
                (typeof this.getUser().customer_id_number === "undefined" ||
                    this.getUser().customer_id_number === null) &&
                this.getUser().roles.length > 0
            ) {
                /*confirm customer*/
                if (this.getUser().roles[0].name === this.constRoles.CUSTOMER) {
                    await errorAlert("Missing CRR ID in profile!", "Error");
                    return;
                }
            }
            if (
                (typeof this.getUser().phone === "undefined" ||
                    this.getUser().phone === null) &&
                this.getUser().roles.length > 0
            ) {
                if (this.getUser().roles[0].name === this.constRoles.CUSTOMER) {
                    await errorAlert("Missing phone in profile!", "Error");
                    return;
                }
            }
            let data = {
                amount: this.total,
                payment_method_id: this.payment_method,
                invoices: this.invoices,
                invoicesToPay: this.invoicesToPay,
                cancel_existing_invoice: false,
            };
            if (data.payment_method_id > 0) {
                try {
                    let checkExistSadad = await this.$axios.post(
                        "checkInvoiceSadadExists",
                        data
                    );
                    if (checkExistSadad.data.status_code == "200") {
                        if (checkExistSadad.data.cancel_existing_invoice == true) {
                            const {isConfirmed} = await confirmOptionAlert(
                                "Sadad Transaction against this invoice already exists, Please Pay or cancel before generating new Sadad Transaction"
                            );
                            if (isConfirmed) {
                                data.cancel_existing_invoice = checkExistSadad.data.cancel_existing_invoice;
                                let payment_generate = await this.$axios.post("payment", data);
                                if (payment_generate.data.status_code == "422") {
                                    errorAlert(payment_generate.data.message, "Error");
                                } else {
                                    successAlert(payment_generate.data.message, "Successfully");
                                    this.$emit("paid");
                                }
                            }
                        } else {
                            let payment_generate = await this.$axios.post("payment", data);
                            if (payment_generate.data.status_code == "422") {
                                errorAlert(payment_generate.data.message, "Error");
                            } else {
                                successAlert(payment_generate.data.message, "successful");
                                this.$emit("paid");
                            }
                        }
                    }
                } catch (err) {
                    errorAlert(err.message, "Error");
                }

                //   await this.$axios.post('payment', data)
                //         .then((res) => {
                //             if (res.data.status_code == '422') {
                //                 errorAlert(res.data.message, 'Error');
                //             }else{
                //                 successAlert(res.data.message, 'successful');
                //                 this.$emit('paid');
                //             }
                //         })
                //         .catch((err) => {
                //             console.log(err.data.message)
                //             errorAlert(err.data.message, 'Error');

                //         });
            } else {
                this.paymentError = true;
            }
        },
        paymentErrorHandler() {
            this.paymentError = false;
        },
        setAltImage(event) {
            event.target.src = "https://place-hold.it/600x600&text=color&fontsize=58";
        },
        async getPaymentMethods() {
            const {data} = await this.$axios.get("/paymentMethod");
            this.paymentMethods = data;
        },
    },
    updated() {
        this.calculateTotal();
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
};
</script>

<style scoped>
.checkoutPanel {
    background: white;
    position: sticky;
    top: 160px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    align-self: flex-start;
}

.heading {
    background: #d8d7d7;
    font-size: 16px;
}
</style>

<template>
    <div>

        <div class="mt-4">
            <div class="d-flex justify-content-between">
                <h3 class="text-capitalize">
                    #Sadad Paid Invoices
                </h3>
                <div class="d-flex items-center justify-content-end">
                    <b-button class="btn px-3 ml-2 pr-2 btn-info" @click="markInvoicesProcessed">Mark Invoices Processed</b-button>
                    <CustomerDropdown
                        @filterCustomerId="filterSadad"
                        class="mx-2"
                    />
                    <date-picker
                        v-model="start_date"
                        @change="getSadadTransactions"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Issue Date Start')"
                        style="width: 150px" class="bg-white ml-2"

                    ></date-picker>
                    <date-picker
                        style="width: 150px" class="bg-white ml-2"
                        v-model="end_date"
                        @change="getSadadTransactions"
                        format="YYYY-MM-DD"
                        type="date"
                        :placeholder="$t('Issue Date End')"

                    ></date-picker>
                    <b-input
                        style="width: 200px" class="bg-white ml-2"
                        v-model="search"
                        @blur="getSadadTransactionsBySearch"
                        :placeholder="$t('Search by SADAD')"

                    ></b-input>
                    <export-excel
                        class="btn px-3 ml-2 pr-2 btn-info"
                        :fetch="fetchDataToExport"
                        :fields="excel_export_fields_name"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        worksheet="InvoicesPaid"
                        :name="
                            'invoices-' +
                            Math.floor(Math.random() * 100) +
                            '-.xls'
                        "
                    >
                        {{ $t("Export To Excel") }}
                    </export-excel>
                </div>
            </div>
            <b-card class="shadow-sm mt-2" body-class="p-0">
                <b-table
                    :busy="loading"
                    head-variant="dark"
                    small
                    :items="items.data"
                    :tbody-tr-class="rowClass"
                    show-empty
                    bordered
                    :fields="fields"
                    responsive
                    class="text-center"
                >
                    <template #cell(sms)="data">
                        <div   class="text-center">
                            <b-form-checkbox
                                class="payCheckbox"
                                v-if="data.item.is_processed == 0"
                                @change="(checked) => checkoutHandler(checked, data.item, 'sadad')"
                            >
                            </b-form-checkbox>
                        </div>
                    </template>
                    <template #cell(sadad_invoice)="data">
                        <b-link
                            v-if="data.item.sadad_invoice.sadad_number"
                            class="text-primary"
                            :to="`/sadad-transactions-invoices/${data.item.sadad_invoice.sadad_number}`"
                        >
                            {{ data.item.sadad_invoice.sadad_number }}
                        </b-link>
                    </template>
                    <template #cell(issue_date)="data">
                        {{ data.item.issue_date | moment }}
                    </template>
                    <template #cell(amount)="data">
                        {{ data.item.amount | comma_separated }}
                    </template>


                </b-table>

                <b-pagination
                    aria-controls="my-table"
                    size="sm"
                    per-page="15"
                    v-model="currentPage"
                    :total-rows="items.total"
                    @input="getSadadTransactions"
                    prev-text="Prev"
                    next-text="Next"
                    class="justify-content-center"
                ></b-pagination>
            </b-card>
        </div>

    </div>
</template>

<script>

import {successAlert,errorAlert} from "@/components/Theme/Alerts";
import CustomerDropdown from "@/components/User/CustomerDropdown";

import moment from "moment";
import isEmpty from "lodash.isempty";
import DatePicker from "vue2-datepicker";
import {cloneDeep } from "lodash";
import Swal from 'sweetalert2'

export default {
    name: "SadadPaidInvoices",
    components: {
        CustomerDropdown,
        DatePicker,
     },

    data() {
        return {
            customer_id: "",
            loading:false,
            checkedSadad: {},
            items: [],
            currentPage: 1,
            statusColor: {
                paid: "table-success",
                pending: "table-warning",
                canceled: "table-danger",
                expired: "table-secondary",
                undue: "table-secondary",
            },
            processedColor: {
                1: "table-success",
                0: "table-secondary",
            },
            start_date: null,
            end_date: null,
            search: null,
            excel_export_fields_name: {
                "Invoice#": "number",
                Customer: "user.name",
                "Contract#": "installment.contract.number",
                "Invoice Date": {
                    field: "installment.created_at",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                "Due Date": {
                    field: "installment.due_date",
                    callback: (value) => {
                        return moment(value).format("DD/MM/YYYY");
                    },
                },
                Status: "status",
                "Sadad#": "sadad_invoice.sadad_number",
                "Paid Sadad Amt": "sadad_invoice.amount",
                "Is Processed": "is_processed",
            },
        };
    },
    created() {
        this.getSadadTransactions();
    },
    methods: {
        async fetchDataToExport() {
            const start_date =
                this.start_date !== null
                    ? moment(this.start_date).format("YYYY-MM-DD")
                    : null;
            const end_date =
                this.end_date !== null
                    ? moment(this.end_date).format("YYYY-MM-DD")
                    : null;
            const search =
                this.search !== null
                    ? encodeURIComponent(this.search.trim())
                    : null;
            const { data } = await this.$axios.get(`SadadPaidInvoices?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${this.currentPage}&export=1`);
            return data.data;
        },
        startDownload() {
            console.log("show loading");
        },
        finishDownload() {
            console.log("hide loading");
        },
        async markInvoicesProcessed(){
            if (!isEmpty(this.checkedSadad['sadad'])) {
                await Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to mark these invoices processed!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const sadadMarkProceessed = [];
                        for (const invoiceArr in this.checkedSadad) {
                            this.checkedSadad[invoiceArr].forEach((item) => {
                                sadadMarkProceessed.push(item.id);
                            });
                        }
                        const data = {
                            invoice: sadadMarkProceessed,
                        };
                        try {
                            this.loading=true;
                             this.$axios.post('sadad_mark_processed_selected', data);

                             successAlert('Updated', 'successful');

                            this.getSadadTransactions();
                            this.checkedSadad =  {};
                            this.loading=false;

                        }
                        catch (e) {
                            this.loading=false;console.log('error ')
                        }
                    }
                })



            }else {
                errorAlert('No Records Selected', 'Please Select  invoices to send sms')
            }

        },
        getSadadTransactionsBySearch() {
            if (this.search.length >= 3) {
                this.getSadadTransactions();
            }
        },
        filterSadad(value) {
            this.customer_id = value;
            this.getSadadTransactions();
        },
        checkoutHandler(checked, item, categoryName) {
            const checkedSadad = cloneDeep(this.checkedSadad);
            if (checked) {
                if (categoryName in checkedSadad) {  //already invoice added in the category
                    checkedSadad[categoryName].push(item);
                } else {
                    checkedSadad[categoryName] = []; //create new  and push it
                    checkedSadad[categoryName].push(item);
                }


            } else {
                checkedSadad[categoryName] = checkedSadad[categoryName].filter(invoice => invoice.id !== item.id)
                if (checkedSadad[categoryName].length === 0) {
                    delete checkedSadad[categoryName];
                }
            }
            this.checkedSadad = checkedSadad;

        },

        async getSadadTransactions() {
            try{
                this.loading=true;
                const start_date = this.start_date !== null ? moment(this.start_date).format("YYYY-MM-DD") : null;
                const end_date = this.end_date !== null ? moment(this.end_date).format("YYYY-MM-DD") : null;
                const search = this.search !== null ?encodeURIComponent(this.search.trim()) : null;
                const {data} = await this.$axios.get(
                    `SadadPaidInvoices?customer_id=${this.customer_id}&start_date=${start_date}&end_date=${end_date}&search=${search}&page=${this.currentPage}`
                );
                this.items = data;
                this.loading=false;
            } catch (err) {
                this.loading=false;
            }

        },

        rowClass(item, type) {
            if (!item || type !== "row") return;
            return this.processedColor[item.is_processed];
        },

    },
    computed:{
        fields() {
            return [
                {
                    label: "#",
                    key: "sms",
                    visible: true,
                    thStyle: {
                        width: "30px",
                    },
                },
                {
                    label: this.$t("SADAD#"),
                    key: "sadad_invoice",
                },
                {
                    label:  this.$t("Customer"),
                    key: "user.name",
                    thStyle: {
                        width: "90px",
                    },
                },
                {
                    label: this.$t("Payer No"),
                    key: "user.payer_number",
                    visible: true,
                    thStyle: {
                        width: "60px",
                    },
                },
                {
                    label: this.$t("Invoice#"),
                    key: "number",
                },
                {
                    label:  this.$t("Payment Method"),
                    key: "sadad_invoice.payment_method.name",
                },
                {
                    label:  this.$t("Amount"),
                    key: "sadad_invoice.amount",
                },


                {
                    label:  this.$t("Status"),
                    key: "status",
                },

            ]
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == "all") {
                this.filterTag = "";
                return;
            } else {
                this.filterTag = to.params.type;
            }
        },
    },
};
</script>

<style scoped>
</style>

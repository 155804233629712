<template>
    <div id="app">
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>

/*.container-fluid[data-v-0c17871c], .container-sm[data-v-0c17871c], .container-md[data-v-0c17871c], .container-lg[data-v-0c17871c], .container-xl[data-v-0c17871c] {*/
/*    width: 100%;*/
/*    padding-right: 0px !important;*/
/*    padding-left: 0px !important;*/
/*    margin-right: auto;*/
/*    margin-left: auto;*/
/*}*/


</style>


<template>
    <section>
        <b-card class="shadow-sm">
            <form @submit.prevent="changePassword">
                <validation-observer ref="profileForm">
                    <div class="grid grid-cols-1 ">

                                                <b-form-group class="col-md-6" label="Current password *">
                                                    <b-form-input type="password" v-model="password.oldPassword" required
                                                                  placeholder=""></b-form-input>
                                                </b-form-group>

                                                <b-form-group class="col-md-6" label="New Password *">
                                                    <b-form-input type="password" v-model="password.password" required
                                                                  placeholder=""></b-form-input>
                                                </b-form-group>
                                                <b-form-group class="col-md-6" label="Confirm New Password *">
                                                    <b-form-input type="password" v-model="password.password_confirmation" required
                                                                  placeholder=""></b-form-input>
                                                </b-form-group>

                        <!--                        <b-form-group label="Old Password *">-->
                        <!--                            <template #label>Old Password <span class="text-danger">*</span></template>-->
                        <!--                            <text-validator v-model="password.oldPassword" rules="required"-->
                        <!--                                            name="old password"></text-validator>-->
                        <!--                        </b-form-group>-->
                        <!--                        <b-form-group label="New Password *">-->
                        <!--                            <template #label>New Password <span class="text-danger">*</span></template>-->
                        <!--                            <text-validator v-model="password.newPassword" rules="required"-->
                        <!--                                            name="new password"></text-validator>-->
                        <!--                        </b-form-group>-->
                        <!--                        <b-form-group label="Confirm Password *">-->
                        <!--                            <template #label>Confirm Password <span class="text-danger">*</span></template>-->
                        <!--                            <text-validator v-model="password.confirmPassword" rules="required"-->
                        <!--                                            name="confirm password"></text-validator>-->
                        <!--                        </b-form-group>-->

                    </div>
                    <div class="text-right">
                        <button class="primary-btn green">Save</button>
                    </div>
                </validation-observer>
            </form>
        </b-card>
    </section>
</template>

<script>
// import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {errorAlert,serverErrorAlert, successAlert,validationAlert} from "../components/Theme/Alerts";

export default {
    name: "ChangePassword",
    components: { ValidationObserver},
    data() {
        return {
            password: {
                oldPassword: '',
                password: '',
                password_confirmation: ''
            },
            userId: '',
        }
    },
    methods: {
        async changePassword(e) {
            e.preventDefault()
            // const valid = await this.$refs.profileForm.validate();
            // if (valid) {
            try {
                 await this.$axios.post(`changePassword/${this.userId}`, this.password)
                 await successAlert('password', 'updated')
                // this.$router.push('profile')
                try {
                   const user = {
                        user:  this.authUser,
                    }
                    await this.$axios.post('logout',user);
                    localStorage.removeItem('user');
                    this.$router.push('/login');
                } catch (e) {
                    serverErrorAlert()
                }
            } catch (e) {
                if (e.response.status === 421) {
                    await errorAlert(e.response.data.message)
                }else if (e.response.status === 422) {
                    await validationAlert(e.response.data.errors)
                }else{
                   await errorAlert('Something went wrong', 'server error')
                }
            }
        }
    },
    created() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.userId = user.id;
        // console.log(this.userId);
    },
    
    computed: {
        authUser() {
            return JSON.parse(localStorage.getItem('user'));
        },
    }

}
</script>

<style scoped>

</style>

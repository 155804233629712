import {some} from "lodash";

export const cart = {
    state: () => {
        return {
            invoices: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
        }
    },
    getters: {
        cart: (state) => {
            return state.invoices
        }
    },
    actions: {
        addToCart({commit, state}, invoice) {
            const invoices = [...state.invoices];
            if (some(invoices, invoice))
                return;
            invoices.push(invoice)
            localStorage.setItem('cart', JSON.stringify(invoices))
            commit('updateInvoices', invoices)
        },
        removeFromCart({commit, state}, invoice) {
            const invoices = [...state.invoices].filter(item => item.id !== invoice.id);
            localStorage.setItem('cart', JSON.stringify(invoices))
            commit('updateInvoices', invoices)
        }
    },
    mutations: {
        updateInvoices(state, payload) {
            state.invoices = payload
            localStorage.setItem('cart', JSON.stringify(payload))
        }
    }
}

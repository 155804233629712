<template>
    <section>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <div class="d-flex justify-content-end align-items-center p-3">
                <div class="d-flex items-center justify-content-end">
                    
                    <label class="mb-0 m-2" size="sm">{{$t('Filter by Payer/Customer/Email/Name')}}</label>

                    <b-input
                        style="width: 200px"
                        class="bg-white "
                        v-model="search"
                        @keyup="filterUsersBySearch"
                        @blur="filterUsersAll"
                        :placeholder="$t('Search here')"
                    ></b-input>

                    <label class="mb-0 m-2" size="sm">{{$t('Filter by role')}}</label>
                    <b-form-select @input="filterUsersByRole" class="ml-3" size="sm" value-field="id" text-field="name"
                                   :options="roles"
                                   style="width: 150px">
                        <template #first>
                            <b-form-select-option  :value="''">-- Please select an option --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <b-button v-can="'create user'" class="primary-btn ml-3" variant="info" size="sm"
                          to="/create-user">{{$t('Add New User')}}
                </b-button>
            </div>
            <b-table
                head-variant="dark"
                class="text-center"
                small
                :items="items.data"
                show-empty
                bordered
                :fields="fields"
                :busy="loading"
                fixed
                responsive
            >
            <template #table-busy>
                    <table-loader />
                </template>
                <template #cell(role)="data">
                    <div v-for="item in data.item.roles" :key="item.id">
                        {{ item.name }}
                    </div>
                </template>
                <template #cell(actions)="data">
                    <div class='d-flex justify-content-center'>
                        <router-link v-can="'edit user'" :to="`/edit-user/${data.item.id}`">
                            <b-icon variant="info" font-scale="1.5" icon="pencil-square"></b-icon>
                        </router-link>
                        <a v-can="'delete user'" @click="deleteUser(data.item.id,$event)" href="/users/delete-user"
                           class="ml-2">
                            <b-icon variant="danger" font-scale="1.5" icon="trash"></b-icon>
                        </a>
                    </div>
                </template>
            </b-table>
            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="items.total"
                @input="getUsers"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
        </b-card>
    </section>
</template>

<script>
import {confirmDeleteAlert, errorAlert, successAlert} from "@/components/Theme/Alerts";
import TableLoader from "@/components/tableLoader";

export default {
    name: "Users",
    mixins: [],
    components: {TableLoader},
    computed:{
        fields() {
            return [
                {
                    label: this.$t('Sr#'),
                    key: 'id'
                },
                {
                    label: this.$t('First Name'),
                    key: 'first_name'
                },
                {
                    label: this.$t('Last Name'),
                    key: 'last_name'
                },
                {
                    label: this.$t('Email'),
                    key: 'email'
                },
                {
                    label: this.$t('CRR ID'),
                    key: 'customer_id_number'
                },
                {
                    label: this.$t('Customer No'),
                    key: 'customer_number'
                },
                {
                    label: this.$t('Payer No'),
                    key: 'payer_number'
                },
                {
                    label: this.$t('Role'),
                    key: 'role'
                },
                {
                    label: this.$t('Actions'),
                    key: 'actions'
                },

            ]
        }
    },
    data() {
        return {

            items: [],
            roles: [],
            selected_role:'',
            user: {},
            currentPage: 1,
            search: null,
            loading:false,
        }
    },
    created() {
        this.getUsers();
        this.getRoles();
    },
    methods: {
        async getUsers() {
            this.loading =true;
            const role = this.selected_role;
            const search = this.search !== null ? encodeURIComponent(this.search.trim()): null;
            const {data} = await this.$axios.get(`user?role=${role}&page=${this.currentPage}&search=${search}`);
            this.items = data;
            this.loading =false;

        },
        async deleteUser(id, e) {
            e.preventDefault()
            const {isConfirmed} = await confirmDeleteAlert('user');
            if (isConfirmed) {
                try {
                    await this.$axios.delete(`user/${id}`);
                    await successAlert('User', 'deleted')
                    await this.getUsers();
                } catch (e) {
                    await errorAlert('User', 'deleting');
                }
            }
        },
        async getRoles() {
            const {data} = await this.$axios.get('role');
            this.roles = data.roles;
        },
        filterUsersByRole(value) {
            this.selected_role = value;
            this.getUsers()
        },
        filterUsersAll(){
            this.getUsers()
        },
        filterUsersBySearch(){
            if (this.search.length >= 3) {
                this.getUsers();
            }
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <section>
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <div class="d-flex justify-content-end align-items-center p-3">
                <b-button v-b-modal.departmentModal v-can="'create department'" class="primary-btn ml-3"
                          variant="info"
                          size="sm">{{ $t('Add New Department') }}
                </b-button>
            </div>

            <b-table head-variant="dark" responsive small :items="departments.data" class="text-center"  bordered :fields="fields">
                <template #cell(role)="data">
                    <div v-for="item in data.item.roles" :key="item.id">
                        {{ item.name }}
                    </div>
                </template>
                <template #cell(actions)="data">
                    <div class='d-flex justify-content-center'>
                        <a v-can="'edit department'" @click.prevent="editDepartment(data.item)"
                           :href="`/department/${data.item.id}/edit`"
                           class="ml-2">
                            <b-icon variant="info" font-scale="1.5" icon="pencil-square"></b-icon>
                        </a>
                        <a v-can="'delete department'" @click.prevent="deleteDepartment(data.item.id)"
                           :href="`/department/${data.item.id}/delete`"
                           class="ml-2">
                            <b-icon variant="danger" font-scale="1.5" icon="trash"></b-icon>
                        </a>
                    </div>
                </template>
            </b-table>
        </b-card>
        <b-modal hide-footer hide-header centered ref="departmentModal" id="departmentModal">
            <DepartmentForm @close="closeModal" :department-prop="department" :form-type="formType"></DepartmentForm>
        </b-modal>
    </section>
</template>

<script>
import DepartmentForm from "@/pages/department/DepartmentForm";
import {confirmDeleteAlert, errorAlert, successAlert} from "@/components/Theme/Alerts";

export default {
    name: "DepartmentList",
    components: {DepartmentForm},
    computed:{
        fields() {
            return [
                {
                    label: this.$t('Sr#'),
                    key: 'id'
                },
                {
                    label: this.$t('Name'),
                    key: 'name'
                },
                {
                    key: this.$t('Actions')
                }
            ]
        }
    },
    data() {
        return {

            // departments: [],
            departments: {},
            formType: 'add',
            department: {}
        }
    },
    created() {
        // let user = JSON.parse(localStorage.getItem("user"));
        this.getDepartments();
        // if((user.roles[0].name === "accountant")){
        //     this.getAccountantDepartments();
        //     console.log(user.roles[0].name);
        // }

    },
    methods: {
        async deleteDepartment(id) {
            const {isConfirmed} = await confirmDeleteAlert('Department');
            if (isConfirmed) {
                try {
                    await this.$axios.delete(`department/${id}`)
                    await successAlert('Department', 'deleted');
                    await this.getDepartments()
                } catch
                    (e) {
                    await errorAlert('Department', 'deleting')
                }
            }
        },
        async getDepartments() {
            const {data} = await this.$axios.get('department');
            this.departments = data;
        },

        async getAccountantDepartments() {
            const {data} = await this.$axios.get('userDepartments');
            this.departments = data;
        },
        editDepartment(department) {
            this.formType = 'edit';
            this.department = {...department}
            this.$refs.departmentModal.show();
        },
        closeModal() {
            this.$refs.departmentModal.hide();
            this.getDepartments();
        }
    }
}
</script>

<style scoped>

</style>

<template>
    <div>
        <!--        <h1 class="py-3 text-capitalize">{{this.$route.params.type }} Contracts</h1>-->
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table head-variant="dark" small class="text-center"
                     :items="items.data"
                     show-empty bordered
                     :fields="fields"
                     responsive

            >


                <template #cell(number)="data">

                    <b-link class="text-dark" :to="{name:'ContractDetails',params:{number: data.item.number}}">
                        {{ data.item.number }}
                    </b-link>

                </template>
                <!--            <template #cell(due_date)="data">-->
                <!--                {{ data.item.due_date | moment }}-->
                <!--            </template>-->
            </b-table>

            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="items.total"
                @input="getInstallments"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>

        </b-card>
    </div>
</template>

<script>
export default {
    name: "PaymentRemaining",

    data() {
        return {
            fields: [

                {
                    label: 'Contract # ',
                    key: 'contract.number'
                },
                {
                    label: 'Contract name',
                    key: 'contract.name'
                },
                {
                    label: 'Installment no',
                    key: 'ref_number'
                },
                {
                    label: 'Due date',
                    key: 'due_date'
                },
                {
                    label: 'Category',
                    key: 'contract.category.name'
                },
            ],
            items: [],
            currentPage: 1,
        }
    },
    created() {
        this.getInstallments();


    },
    methods: {
        async getInstallments() {
            const {data} = await this.$axios.get(`userInstallments?currentPage=${this.currentPage}`);
            this.items = data;
            //duplicate code
            if (this.$route.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = this.$route.params.type;
            }
        },
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == 'all') {
                this.filterTag = '';
                return;
            } else {
                this.filterTag = to.params.type;
            }
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div>
        <!--        <h1 class="py-3 text-capitalize">{{this.$route.params.type }} Contracts</h1>-->
        <b-card class="shadow-sm mt-2" body-class="p-0">
            <b-table
                head-variant="dark"
                small
                :items="items.data"
                :tbody-tr-class="rowClass"
                show-empty
                bordered
                :fields="fields"
                responsive
                class="text-center"
            >
                <template #cell(sadad_number)="data">
                    <b-link
                        v-if="data.item.sadad_number"
                        class="text-primary"
                        :to="`/sadad-transactions-invoices/${data.item.sadad_number}`"
                    >
                        {{ data.item.sadad_number }}
                    </b-link>
                </template>
                <template #cell(issue_date)="data">
                    {{ data.item.issue_date | moment }}
                </template>
                <template #cell(amount)="data">
                    {{ data.item.amount | comma_separated }}
                </template>

                <template #cell(expire_date)="data">
                    {{ data.item.expire_date | moment }}
                </template>
                <template #cell(actions)="data">
                    <div class="d-flex justify-content-center">
                        <b-link
                            v-if="data.item.sadad_number"
                            class="text-primary"
                            :to="`/sadad-transactions-invoices/${data.item.sadad_number}`"
                        >
                            <b-icon
                                variant="info"
                                font-scale="1.5"
                                icon="eye-fill"
                            ></b-icon>
                        </b-link>

                        <span
                            v-if="data.item.status == 'pending'"
                            @click="
                                cancelSdadaInvoices(
                                    data.item.bill_number,
                                    data.item.sadad_number
                                )
                            "
                            class="ml-2"
                            variant="danger"
                        >
                            <b-icon
                                variant="danger"
                                font-scale="1.5"
                                icon="file-excel-fill"
                            ></b-icon
                        ></span>
                    </div>
                </template>
            </b-table>

            <b-pagination
                aria-controls="my-table"
                size="sm"
                per-page="15"
                v-model="currentPage"
                :total-rows="items.total"
                @input="getSadadTransactions"
                prev-text="Prev"
                next-text="Next"
                class="justify-content-center"
            ></b-pagination>
        </b-card>
    </div>
</template>

<script>
import { confirmCancelAlert, successAlert } from "@/components/Theme/Alerts";
import moment from "moment";

export default {
    name: "SadadTransactions",
    computed: {
        fields() {
            return [
                {
                    label: this.$t("Bill#"),
                    key: "bill_number",
                },
                {
                    label: this.$t("SADAD#"),
                    key: "sadad_number",
                },
                {
                    label: this.$t("Customer"),
                    key: "user.name",
                    thStyle: {
                        width: "90px",
                    },
                },
                {
                    label: this.$t("Payment Method"),
                    key: "payment_method.name",
                },
                {
                    label: this.$t("Amount"),
                    key: "amount",
                },
                {
                    label: this.$t("Issue Date"),
                    key: "issue_date",
                },
                {
                    label: this.$t("Expiry Date"),
                    key: "expire_date",
                },
                {
                    label: this.$t("Status"),
                    key: "status",
                },
                {
                    label: this.$t("Actions"),
                    key: "actions",
                },
            ];
        },
    },
    data() {
        return {
            items: [],
            currentPage: 1,
            statusColor: {
                paid: "table-success",
                pending: "table-warning",
                canceled: "table-danger",
                expired: "table-secondary",
            },
        };
    },
    created() {
        this.getSadadTransactions();
    },
    methods: {
        async getSadadTransactions() {
            const { data } = await this.$axios.get(
                `sadadTransactions?currentPage=${this.currentPage}`
            );
            this.items = data;
            //duplicate code
            if (this.$route.params.type == "all") {
                this.filterTag = "";
                return;
            } else {
                this.filterTag = this.$route.params.type;
            }
        },
        rowClass(item, type) {
            if (!item || type !== "row") return;
            return this.statusColor[item.status];
        },
        async cancelSdadaInvoices(bill_number, sadad_number) {
            const { isConfirmed } = await confirmCancelAlert("Sadad Invoices");
            if (isConfirmed) {
                let data = {
                    bill_number: bill_number,
                    sadad_number: sadad_number,
                };
                await this.$axios.post("cancelSadadInvoicesById", data);

                await successAlert("Success!", "successful");
                await this.getSadadTransactions();
            }
        },
    },
    filters: {
        moment: function (date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
    watch: {
        $route(to) {
            this.type = to.params.type;
            if (to.params.type == "all") {
                this.filterTag = "";
                return;
            } else {
                this.filterTag = to.params.type;
            }
        },
    },
};
</script>

<style scoped></style>

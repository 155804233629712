<template>
    <section>
        <b-card>
            <div v-can="'create role'" class="text-right">
                <b-button class="primary-btn ml-3" variant="secondary" size="sm" @click="showRoleForm">{{ $t('Add New Role') }}
                </b-button>
            </div>
            <b-table class="mt-3 text-center" bordered show-empty small head-variant="dark" :fields="fields" :items="roles">
                <template #cell(created_at)="data">
                    {{moment(data.item.created_at).format('DD/MM/YYYY')}}
                </template>
                <template #cell(actions)="data">
                    <div  class='d-flex justify-content-center'>
                        <a v-can="'edit role'"  @click.prevent="editPermissions(data.item)"
                           href="/roles/edit-permissions">
                            <b-icon variant="secondary" font-scale="1.5" icon="key"></b-icon>
                        </a>
                        <a v-can="'delete role'" v-if="data.item.name != 'admin' && data.item.name != 'accountant'" @click.prevent="deleteRole(data.item.id)" href="/roles/delete-role"
                           class="ml-2">
                            <b-icon variant="danger" font-scale="1.5" icon="trash"></b-icon>
                        </a>
                    </div>
                </template>
            </b-table>
        </b-card>
        <b-modal centered ref="rolePermissions" size="xl" hide-header hide-footer>
            <RolePermissions @close="$refs.rolePermissions.hide();getRoles()" :permissions="permissions" :role="role"/>
        </b-modal>
        <b-modal centered ref="roleForm" hide-footer hide-header>
            <RoleForm @close="hideModal" :formType="formType"/>
        </b-modal>
    </section>
</template>
<script>
import {confirmDeleteAlert, errorAlert} from "@/components/Theme/Alerts";
import RolePermissions from "@/components/User/RolePermissions";
import RoleForm from "@/components/Role/RoleForm";
import moment from "moment";


export default {
    name: "Roles",
    components: {RoleForm, RolePermissions},
    computed:{
        fields() {
            return [
                {
                    key: 'name',
                    label: this.$t('Name')
                },
                {
                    key: 'created_at',
                    label: this.$t('Created Date')
                },
                {
                    key: 'actions',
                    label: this.$t('Actions')
                }
            ]
        }
    },
    data() {
        return {

            roles: [],
            permissions: [],
            role: {},
            formType: 'add'
        }
    },
    created() {
        this.getRoles()
    },
    methods: {
        async getRoles() {
            const {data} = await this.$axios.get('role');
            this.roles = data.roles
            this.permissions = data.permissions
        },
        async deleteRole(id) {
            const res = await confirmDeleteAlert('role');
            if (res.isConfirmed) {
                try {
                    await this.$axios.delete(`role/${id}`);
                    await this.getRoles();
                } catch (e) {
                    await errorAlert('Role', 'deleting')
                }
            }
        },
        async editPermissions(role) {
            this.role = role;
            this.$refs.rolePermissions.show();
        },
        showRoleForm(e, formType = 'add') {
            this.formType = formType
            this.$refs.roleForm.show();
        },
        async hideModal() {
            this.$refs.roleForm.hide();
            await this.getRoles()
        },
        moment(){
            return moment()
        }
    }
}
</script>

<style scoped>

</style>

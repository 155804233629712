<script>

import {Pie, mixins} from "vue-chartjs"

export default {
    name: "Pie",
    mixins: [mixins.reactiveProp],
    extends: Pie,
    props: ['options'],
    mounted() {
        this.renderChart(this.chartdata, this.options)
    },
}
</script>

<style scoped>

</style>

export const invoice = {
    amount: '',
    due_date: '',
    total: '',
    balance: '',
    status: ''
}

export const invoiceOptions = ['overdue', 'paid', 'due', 'undue']

export const user = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    role: '',
    department_id: '',
    departments: [],
    customer_id_number:'',
    customer_number:'',
    payer_number:'',
}

export const roles = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    ACCOUNTANT: 'accountant'
}

<template>
    <validation-observer ref="invoiceForm">
        <form action="">
            <div class="grid grid-cols-3 gap-x-6">
                <b-form-group label="Amount">
                    <text-validator v-model="invoice.amount" name="Amount"
                                    rules="required"></text-validator>
                </b-form-group>
                <b-form-group label="Due Date">
                    <text-validator v-model="invoice.due_date" type="date" name="Due Date"
                                    rules="required"></text-validator>
                </b-form-group>
                <b-form-group label="Total">
                    <text-validator v-model="invoice.total" name="Total" rules="required"></text-validator>
                </b-form-group>
                <b-form-group label="Balance">
                    <text-validator v-model="invoice.balance" name="Balance"
                                    rules="required"></text-validator>
                </b-form-group>
                <b-form-group label="Status">
                    <select-validator :reduce="false" v-model="invoice.status" rules="required"
                                      name="Status"
                                      :options="invoiceOptions"></select-validator>
                </b-form-group>
            </div>
            <div class="text-right">
                <button type="button" @click="save" class="primary-btn green">Save</button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import SelectValidator from "@/components/Validators/SelectValidator";
import TextValidator from "@/components/Validators/TextValidator";
import {ValidationObserver} from "vee-validate";
import {invoice, invoiceOptions} from "./../../constants";
import {serverErrorAlert, successAlert} from "@/components/Theme/Alerts";

export default {
    name: "InvoiceForm",
    components: {SelectValidator, TextValidator, ValidationObserver},

    data() {
        return {
            invoice: {...invoice}
        }
    },
    props: {
        type: {
            type: String,
            default: 'add'
        }
    },
    computed: {
        invoiceOptions() {
            return invoiceOptions
        }
    },
    created() {
        const {id} = this.$route.params;
        if (this.type === 'edit') {
            this.getInvoice(id)
        }
    },
    methods: {
        async save() {
            const valid = await this.$refs.invoiceForm.validate();
            if (valid) {
                try {
                    const data = {...this.invoice};
                    if (this.type === 'add') {
                        await this.$axios.post('invoice', data)
                    } else {
                        await this.$axios.put(`invoice/${data.id}`, data)
                    }
                    await successAlert('Invoice', 'saved')
                } catch (e) {
                    await serverErrorAlert();
                }
            }
        },
        async getInvoice(id) {
            const {data} = await this.$axios.get(`invoice/${id}`);
            this.invoice = {...data}
        }
    },
}
</script>

<style scoped>

</style>

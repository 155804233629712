<template>
    <section>
        <div v-if="!cart.length" class="bg-white rounded shadow px-3 py-5 font-weight-semi-bold text-2xl">
            There are no invoices in cart!
        </div>
        <div v-else>
            <b-table class="text-center" small responsive fixed head-variant="dark" bordered :fields="fields" :items="cart">
                <template #cell(created_at)="data">
                    {{ data.item.created_at | moment }}
                </template>
                <template #cell(due_date)="data">
                    {{ data.item.due_date | moment }}
                </template>
                <template #cell(installment.contract.number)="data">
                    <b-link v-if="data.item.installment" class="text-primary"
                            :to='`/contract-details/${data.item.installment.contract.number}`'>
                        {{ data.item.installment.contract.number }}
                    </b-link>
                </template>
                <template #cell(actions)="data">
                    <a href="" @click.prevent="removeFromCart(data.item)">
                        <b-icon font-scale="1.4" icon="trash" class="text-danger"/>
                    </a>
                </template>
                <template #cell(pay_amount)="data">
                    <b-form-input :value="invoicesToPay[data.item.id].balance"
                                  :formatter="(value) => balanceFormatter(value,data.item.balance)"
                                  @change="(val) => inputBalanceHandler(val, data.item.id)"/>
                </template>
                <template #custom-foot>
                    <b-tr>
                        <b-td class="text-right font-weight-semi-bold" colspan="11">Total Amount</b-td>
                        <b-td>{{ total }}</b-td>
                    </b-tr>
                </template>
            </b-table>
            <b-card>
                <div class="m-4">
                    <b-form-group label="Payment methods" v-slot="{ ariaDescribedby }">
                        <div class="d-flex mt-4">
                            <div class="ml-4" v-for="method in paymentMethods" :key="method.id">
                                <b-form-radio @change="paymentError=false" v-model="payment_method"
                                              :aria-describedby="ariaDescribedby" name="paymentMethod"
                                              :value="method.id"
                                              required>
                                    <img
                                        :src="method.image.path" width="70px" height="40px" alt="">
                                </b-form-radio>
                            </div>
                        </div>
                        <div v-if="paymentError" class="alert alert-danger mt-4">
                            Please select a payment method
                        </div>
                    </b-form-group>
                    <div class="text-center">
                        <button @click="pay" class="btn btn-primary text-center">Pay Now</button>
                        <b-button @click="$emit('cancelPayment')" class="ml-2" variant="danger">Cancel</b-button>
                    </div>
                </div>
            </b-card>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import moment from "moment";
import {cloneDeep, keyBy, sumBy, toArray} from "lodash";

import {successAlert} from "@/components/Theme/Alerts";

export default {
    name: "cart",
    data: () => ({
        fields: [
            {
                label: 'Invoice #',
                key: 'number',
                thStyle: {
                    width: '70px'
                }
            },
            {
                label: 'Customer',
                key: 'user.name',
                thStyle: {
                    width: '90px'
                }
            },
            {
                label: 'Contract #',
                key: 'installment.contract.number',
                thStyle: {
                    width: '90px'
                }
            },
            {
                label: 'Invoice Date',
                key: 'created_at',
                thStyle: {
                    width: '100px'
                }
            },
            {
                label: 'Due Date',
                key: 'due_date',
                thStyle: {
                    width: '100px'
                }
            },
            {
                label: 'status',
                key: 'status',
                thStyle: {
                    width: '70px'
                }
            },
            {
                label: 'Paid Amount',
                key: 'amount',
                thStyle: {
                    width: '60px'
                }
            },
            {
                label: 'Total',
                key: 'total',
                thStyle: {
                    width: '60px'
                }
            },
            {
                label: 'Balance',
                key: 'balance',
                thStyle: {
                    width: '60px'
                }
            },
            {
                label: 'Installment Ref.',
                key: 'installment.ref_number',
                thStyle: {
                    width: '60px'
                }
            },
            {
                label: 'Department',
                key: 'installment.contract.category.name',
                thStyle: {
                    width: '90px'
                }
            },
            {
                label: 'Pay Amount',
                key: 'pay_amount',
                thStyle: {
                    width: '100px'
                }
            },
            {
                key: 'actions',
                thStyle: {
                    width: '60px'
                }
            }
        ],
        paymentMethods: [],
        payment_method: '',
        paymentError: false,
        total: 0,
        invoicesToPay: []
    }),
    created() {
        this.getPaymentMethods();
        this.invoicesToPay = keyBy(cloneDeep(this.cart), 'id')
        this.total = sumBy(this.cart, 'balance');
    },
    methods: {
        ...mapActions(['removeFromCart']),
        async getPaymentMethods() {
            const {data} = await this.$axios.get('/paymentMethod');
            this.paymentMethods = data
        },
        async pay() {
            if (!this.payment_method) {
                this.paymentError = true
                return
            }
            const invoicesToPay = toArray(cloneDeep(this.invoicesToPay))
            const data = {
                amount: this.total,
                payment_method_id: this.payment_method,
                invoices: {invoices: invoicesToPay},
            };
            try {
                await this.$axios.post('payment', data);
                await successAlert('Payment', 'successful');
                const newCart = [];
                invoicesToPay.forEach(invoice => {
                    if (invoice.balance !== 1)
                        newCart.push(invoice)
                })
                this.$store.commit('updateInvoices', newCart);
                this.payment_method = '';
            } catch (e) {
                alert('error payment')
            }
        },
        /**
         * update amount to pay for invoice
         * @param value
         * @param index
         */
        inputBalanceHandler(value, index) {
            const invoices = cloneDeep(this.invoicesToPay);
            invoices[index].balance = Number(value)
            this.invoicesToPay = invoices
            this.total = sumBy(toArray(invoices), 'balance');
        },
        balanceFormatter(value, balance) {
            const payPatt = new RegExp(/^\d+$/);
            if (!payPatt.test(value)) {
                return 0;
            }
            if (Number(value) > balance) {
                return balance
            }
            return Number(value)
        }
    },
    computed: {
        ...mapGetters(['cart'])
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
}
</script>

<style scoped>

</style>

<template>
    <div>
        <section>
            <b-card>
                <invoice-form type="edit"></invoice-form>
            </b-card>
        </section>
    </div>
</template>

<script>
import InvoiceForm from "@/components/Invoice/InvoiceForm";

export default {
    name: "EditInvoice",
    components: {InvoiceForm}
}
</script>

<style scoped>

</style>

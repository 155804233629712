import {cloneDeep} from "lodash";
import {mapActions} from "vuex";

// import isEmpty from "lodash.isempty";

const checkoutMixin = {
    data() {
        return {
            // checkedInvoices: {},
            // openCheckout: false,
        }
    },
    created() {

    },
    methods: {
        ...mapActions(['addToCart', 'removeFromCart']),
        checkoutHandler(checked, item, categoryName) {
            const checkedInvoices = cloneDeep(this.checkedInvoices);
            if (checked) {
                if (categoryName in checkedInvoices) {  //already invoice added in the category
                    checkedInvoices[categoryName].push(item);
                } else {
                    checkedInvoices[categoryName] = []; //create new  and push it
                    checkedInvoices[categoryName].push(item);
                }
                this.addToCart(item)

            } else {
                checkedInvoices[categoryName] = checkedInvoices[categoryName].filter(invoice => invoice.id !== item.id)
                if (checkedInvoices[categoryName].length === 0) {
                    delete checkedInvoices[categoryName];
                }
                this.removeFromCart(item)
            }
            this.checkedInvoices = checkedInvoices;
            this.checkout();
        },
        checkout() {
            this.openCheckout = !!Object.keys(this.checkedInvoices).length
        },

        // checkedInvoiceHandler(val, id, balance, arr) {
        //     if (!isEmpty(this.checkedInvoices[arr])) {
        //         const checkedInvoices = cloneDeep(this.checkedInvoices);
        //         const item = checkedInvoices[arr].find(inv => inv.id === id);
        //         if (val > 0 && val <= balance) {
        //             item.balance = val;
        //         } else {
        //             item.balance = 0;
        //         }
        //         this.checkedInvoices = checkedInvoices;
        //     }
        // },
        // hasChecked(id, arr) {
        //     if (!isEmpty(this.checkedInvoices[arr])) {
        //         return this.checkedInvoices[arr].some(inv => inv.id === id);
        //     } else {
        //         return false;
        //     }
        // },
        // getCheckInvoice(id, arr) {
        //     if (!isEmpty(this.checkedInvoices[arr])) {
        //         return this.checkedInvoices[arr].find(inv => inv.id === id);
        //     }
        // },
    }
}
export default checkoutMixin;

import vue from "vue";

import Vuex from "vuex";
import {cart} from "@/store/modules/cart";
import {i18n} from "@/plugins/i18n";

vue.use(Vuex)
export const store = new Vuex.Store({
    strict: true,
    state: {
        lang: i18n.locale
    },
    getters:{
        selectedLanguage: (state) => state.lang,
    },
    actions: {
        changeLanguage: ({commit}, lang) => {
            commit('changeLanguage', lang);
            localStorage.setItem("language_selected",lang);
        }
    },
    mutations: {
        changeLanguage: (state, lang) => state.lang = lang
    },
    modules: {
        cart: cart
    }
})

